import React, { useState } from "react";
import CategoryContext from "./CategoryContext";
import { getAllFormData } from "../../constants/apiService";
import { toast } from "react-toastify";

const CategoryState = (props) => {
  const [cloading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [totalCategory, setTotalCategory] = useState(0);

  const fetchAllCategory = async () => {
    try {
      setLoading(true);
      const url = "/category/getallcategory";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let category = result.data;
        setTotalCategory(category.length);
        setCategoryData(category);
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  return (
    <CategoryContext.Provider
      value={{ fetchAllCategory, totalCategory, cloading, categoryData }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryState;
