import React from "react";
import "./NotificationItem.css";
import { Link } from "react-router-dom";

export default function NotificationItem(props) {
  const { title, content, action, actionType, date } = props;
  let customClass = "";
  let linkURL = "#";

  if (action === "Reservation Cancelled") {
    customClass = "cancel";
  } else if (action === "Request for Cancel") {
    customClass = "cancelReq";
  } else if (action === "Guest Query") {
    customClass = "contactUs";
  } else if (action === "Refunded to the Customer") {
    customClass = "refund";
  }

  if (actionType === "Booking") {
    linkURL = "/admin/ViewBookings";
  } else if (actionType === "ContactUs") {
    linkURL = "/admin/contact";
  }

  return (
    <Link to={linkURL} className={"notificationWrapper"}>
      <div className={"notificationContent " + customClass}>
        <div className="notiLeft">
          <div className="notiTitle">{title}</div>
          <div className="notiContent">{content}</div>
        </div>
        <div className="notiRight">
          <div className="notiAction">{action}</div>
          <div className="notiDate">{date.slice(0, 10)}</div>
        </div>
      </div>
    </Link>
  );
}
