import React, { useEffect, useState } from "react";
import style from "./AddCar.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MdKeyboardBackspace } from "react-icons/md";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import { getAllFormData, storeFormData } from "../../../constants/apiService";

import { Formik, Form, Field } from "formik";
import { FormGroup, Input } from "reactstrap";
import * as Yup from "yup";

const AddCar = () => {
  const initialMenu = {
    name: "",
    perHourRent: "",
    capacity: "",
    luggageCapacity: "",
    imageUrl: null,
    description: "",
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    imageUrl: Yup.mixed().required("Required"),
    // fuelType: Yup.string().required("Required"),
    // category: Yup.string().required("Required"),
    perHourRent: Yup.string().required("Required"),
    capacity: Yup.string().required("Required"),
    luggageCapacity: Yup.string().required("Required"),
    // description: Yup.string().required("Required"),
  });

  const navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState();
  const [ckPara, setCkPara] = useState("Description");
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedFuelType, setSelectedFuelType] = useState("Petrol");

  const error = "";

  const fetchAllCategory = async () => {
    const url = "category/getallcategory";

    const result = await getAllFormData(url);
    if (result.status === 200) {
      let categories = await result.data;

      const convertedData = await categories.map((item) => {
        return {
          label: item.title,
          value: item.title,
        };
      });
      setSelectedCategory(convertedData[0].value);
      setCategories(convertedData);
    } else if (result.status === 404) {
      toast.error(result.statusText);
    } else if (result.status === 400) {
      toast.error(result.data.errors[0].message);
    } else {
      toast.error("error fetching data");
    }
  };

  const handleChange = async (value) => {
    setSelectedCategory(value);
  };

  const handleFuelTypeChange = async (value) => {
    setSelectedFuelType(value);
  };

  const onSubmitClick = async (menuData) => {
    // e.preventDefault();
    setLoading(true);

    const url = "/cars/addcar";

    let menuItemsData = new FormData();
    menuItemsData.append("imageUrl", menuData.imageUrl);
    menuItemsData.append("name", menuData.name);
    menuItemsData.append("perHourRent", menuData.perHourRent);
    menuItemsData.append("capacity", menuData.capacity);
    menuItemsData.append("luggageCapacity", menuData.luggageCapacity);
    // menuItemsData.append("description", menuData.description);
    menuItemsData.append("description", ckPara);
    menuItemsData.append("fuelType", selectedFuelType);
    menuItemsData.append("category", selectedCategory);

    const result = await storeFormData(url, menuItemsData);

    if (result.status === 200) {
      setLoading(false);
      navigate("/admin/car");
      toast.success("car created sucessfully");
    } else if (result.response.status === 404) {
      setLoading(false);
      toast.error(result.response.statusText);
    } else if (result.response.status === 400) {
      setLoading(false);
      toast.error(result.response.data.errors[0].message);
    } else {
      setLoading(false);
      toast.error(result.response.data.reason);
    }
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  return (
    <div className={style.AddCarWrapper}>
      <div className={style.AddCar}>
        <div className={style.FormHeading}>
          <div className={style.FH_left}>
            <Link to="/admin/car" className={style.baseBTN_nobg}>
              <MdKeyboardBackspace />
            </Link>
          </div>
          <div className={style.FH_right}>
            <h1>Add Car</h1>
          </div>
        </div>
        <Formik
          initialValues={initialMenu}
          onSubmit={onSubmitClick}
          validationSchema={SignupSchema}
        >
          {(formProps) => (
            <Form
              className={"av-tooltip tooltip-label-right " + style.AdminForm}
            >
              {error ? (
                <FormGroup className="error-l-75">
                  <h1 style={{ color: "red" }}>error goes here{error}</h1>
                </FormGroup>
              ) : null}
              <div className="container">
                <div className="row">
                  <h1>Vehicle Name</h1>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=" Car name"
                  />
                  {formProps.errors.name && formProps.touched.name ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.name}
                    </div>
                  ) : null}

                  <h1>Image</h1>
                  <Input
                    type="file"
                    name="imageUrl"
                    className="form-control"
                    onChange={(event) => {
                      formProps.setFieldValue(
                        "imageUrl",
                        event.target.files[0]
                      );
                    }}
                  />
                  {formProps.errors.imageUrl && formProps.touched.imageUrl ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.imageUrl}
                    </div>
                  ) : null}

                  <h1>Rent ($ Per Hour)</h1>
                  <Field
                    type="number"
                    className="form-control"
                    name="perHourRent"
                    rows="3"
                  />
                  {formProps.errors.perHourRent &&
                  formProps.touched.perHourRent ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.perHourRent}
                    </div>
                  ) : null}

                  <h1>Number of Passengers Capacity</h1>
                  <Field
                    type="number"
                    className="form-control"
                    name="capacity"
                    rows="3"
                  />
                  {formProps.errors.capacity && formProps.touched.capacity ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.capacity}
                    </div>
                  ) : null}

                  <h1>Luggage Capacity</h1>
                  <Field
                    type="number"
                    className="form-control"
                    name="luggageCapacity"
                    rows="3"
                  />
                  {formProps.errors.luggageCapacity &&
                  formProps.touched.luggageCapacity ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.luggageCapacity}
                    </div>
                  ) : null}

                  <h1>Category</h1>
                  <Select
                    className="form-control w-100"
                    defaultValue={selectedCategory}
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => handleChange(value)}
                    options={categories}
                    size="large"
                  />

                  <h1>Fuel Type</h1>
                  <Select
                    className="form-control w-100"
                    defaultValue={selectedFuelType}
                    size="large"
                    style={{
                      width: "100%",
                      fontSize: "26px",
                    }}
                    onChange={(value) => handleFuelTypeChange(value)}
                    options={[
                      {
                        value: "Electric",
                        label: "Electric",
                      },
                      {
                        value: "Gas",
                        label: "Gas",
                      },
                      {
                        value: "Disel",
                        label: "Disel",
                      },
                      {
                        value: "Hybrid",
                        label: "Hybrid",
                      },
                    ]}
                  />

                  <h1>Description </h1>

                  <div className={style.CKEditor}>
                    <CKEditor
                      name="description"
                      editor={ClassicEditor}
                      data={ckPara}
                      onChange={(event, editor) => {
                        const dataPara = editor.getData();
                        setCkPara(dataPara);
                      }}
                    />
                  </div>

                  {/* <Field
                    as="textarea"
                    className="form-control"
                    name="description"
                    rows="5"
                    style={{ fontSize: "26px", width: "100%", padding: "15px" }}
                  /> */}
                  {/* {formProps.errors.description &&
                  formProps.touched.description ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.description}
                    </div>
                  ) : null} */}
                </div>
              </div>
              {/* <button
                color="secondary"
                outline
                onClick={() => handleCancelButton()}
                
              >
                Cancel
              </button> */}
              {loading ? (
                <button type="submit" color="primary" outline disabled>
                  ... Processing ...
                </button>
              ) : (
                <button
                  type="submit"
                  color="primary"
                  outline
                  disabled={loading}
                >
                  Submit
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCar;
