import React from "react";

export default function HeroVideoFormTag(props) {
  const { coverVideoData, keyId } = props;

  return (
        <video
          id={"coverVideo" + keyId}
          width="320"
          height="240"
          autoPlay
          muted
          loop
          src={coverVideoData[keyId]?.videoUrl}
          alt={"Cover Video 00" + keyId}
        />
  );
}
