import React, { useState } from "react";
import style from "./AddCategory.module.css";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
import { MdKeyboardBackspace } from "react-icons/md";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { storeFormData } from "../../../constants/apiService";

import { Formik, Form, Field } from "formik";
import { FormGroup, Input } from "reactstrap";
import * as Yup from "yup";

const AddCategory = () => {
  const initialMenu = {
    title: "",
    imageUrl: null,
    description: "",
  };

  const SignupSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    imageUrl: Yup.mixed().required("Required"),
    description: Yup.string().required("Required"),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const error = "";

  const onSubmitClick = async (menuData) => {
    setLoading(true);

    const url = "/category/addcategory";

    let menuItemsData = new FormData();
    menuItemsData.append("imageUrl", menuData.imageUrl);
    menuItemsData.append("title", menuData.title);
    menuItemsData.append("description", menuData.description);

    const result = await storeFormData(url, menuItemsData);

    if (result.status === 200) {
      setLoading(false);
      navigate("/admin/category");
      toast.success("Category added sucessfully");
    } else if (result.response.status === 404) {
      setLoading(false);
      toast.error(result.response.statusText);
    } else if (result.response.status === 400) {
      setLoading(false);
      toast.error(result.response.data.errors[0].message);
    } else {
      setLoading(false);
      toast.error(result.response.data.reason);
    }
  };

  return (
    <div className={style.AddCategoryWrapper}>
      <div className={style.AddCategory}>
        <div className={style.FormHeading}>
          <div className={style.FH_left}>
            <Link to="/admin/category" className={style.baseBTN_nobg}>
              <MdKeyboardBackspace />
            </Link>
          </div>
          <div className={style.FH_right}>
            <h1>Add Category</h1>
          </div>
        </div>
        <Formik
          initialValues={initialMenu}
          onSubmit={onSubmitClick}
          validationSchema={SignupSchema}
        >
          {(formProps) => (
            <Form
              className={"av-tooltip tooltip-label-right " + style.AdminForm}
            >
              {error ? (
                <FormGroup className="error-l-75">
                  <h1 style={{ color: "red" }}>error goes here{error}</h1>
                </FormGroup>
              ) : null}
              <div className="container">
                <div className="row">
                  <h1>Category Title</h1>
                  <Field
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Category Title"
                  />
                  {formProps.errors.name && formProps.touched.name ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.name}
                    </div>
                  ) : null}

                  <h1>Description </h1>
                  <Field
                    as="textarea"
                    className="form-control"
                    name="description"
                    rows="5"
                    style={{ fontSize: "26px", width: "100%", padding: "15px" }}
                  />
                  {formProps.errors.description &&
                  formProps.touched.description ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.description}
                    </div>
                  ) : null}

                  <h1>Image</h1>
                  <Input
                    type="file"
                    name="imageUrl"
                    className="form-control"
                    onChange={(event) => {
                      formProps.setFieldValue(
                        "imageUrl",
                        event.target.files[0]
                      );
                    }}
                  />
                  {formProps.errors.imageUrl && formProps.touched.imageUrl ? (
                    <div
                      className="invalid-feedback d-block"
                      style={{ color: "red" }}
                    >
                      {formProps.errors.imageUrl}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <button
					color="secondary"
					outline
					onClick={() => handleCancelButton()}
					
				  >
					Cancel
				  </button> */}
              {loading ? (
                <button type="Submit" color="primary" outline disabled>
                  ... Processing ...
                </button>
              ) : (
                <button
                  type="Submit"
                  color="primary"
                  outline
                  disabled={loading}
                >
                  Submit
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCategory;
