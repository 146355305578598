import React, { useEffect } from "react";
import style from "./ContactPage.module.css";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { getAllData, storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import { useState } from "react";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import MediaIcon from "../../Components/MediaIcon/MediaIcon";

export default function ContactPage() {
  const [loading, setLoading] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState();
  const [contactData, setContactData] = useState({
    name: "-",
    contact_number: "-",
    address: "-",
    email: "-",
  });

  const dummyArr = [0, 1];

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const url = "/contactinfo/getallcontactinfo/";
      const result = await getAllData(url);
      if (result.status === 200) {
        const collectedData = result.data.data[0];
        setContactData({
          name: collectedData.name,
          contact_number: collectedData.phone_number,
          address: collectedData.address,
          email: collectedData.email,
        });
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  const fetchSocialMediaData = async () => {
    setLoading(true);
    try {
      const url = "/social/getallsocial/";
      const result = await getAllData(url);
      if (result.status === 200) {
        setSocialMediaData(result.data.data);
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = "/contacts/addcontact";

    const loginData = {
      name: enterdData.name,
      email: enterdData.email,
      mobileNumber: enterdData.mobileNumber,
      address: enterdData.address,
    };
    const response = await storeData(url, loginData);

    if (response.data) {
      toast.success("We will contact you soon.");
      setLoading(false);
      refreshPage();
    } else {
      toast.error("You have already mailed us. We will contact you soon.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
    fetchSocialMediaData();
  }, []);

  return (
    <>
      {loading && (
        <div className={style.ContactWrapper}>
          <div className={style.Heading}>Get In Touch With Us</div>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.H2Div}></div>
                  {/* <div className={style.H1Div}></div> */}
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {contactData && !loading && (
        <div className={style.ContactWrapper}>
          <div className={style.Heading}>Get In Touch With Us</div>
          <div className={style.ContactContainer}>
            <div className={style.ContactDiv1}>
              <div className={style.ContactUs}>
                <h1>Contact Us</h1>
                <hr className={style.ContactHR} />
                <h3>Contact us 24 hours, 7 days a week</h3>
                <h3>You may also call us at : {contactData.contact_number}</h3>
                <br />
                <form onSubmit={handleSubmit}>
                  <div className={style.inputDiv}>
                    <h2>Name</h2>
                    <input type="text" required name="name"></input>
                  </div>
                  <div className={style.inputDiv}>
                    <h2>Phone Number</h2>
                    <input type="number" required name="mobileNumber"></input>
                  </div>
                  <div className={style.inputDiv}>
                    <h2>Email</h2>
                    <input type="email" required name="email"></input>
                  </div>
                  <div className={style.inputDiv}>
                    <h2>How can we help you?</h2>
                    <textarea type="text" required name="address"></textarea>
                  </div>

                  {loading ? (
                    <button disabled>... Loading ...</button>
                  ) : (
                    <button>Submit</button>
                  )}
                </form>
              </div>
            </div>

            <div className={style.ContactDiv2}>
              <div className={style.ContactInfo}>
                <h1>Contact Info</h1>
                <hr className={style.ContactHR} />
                {/* <h2>{contactData.name}</h2> */}
                {/* <br /> */}
                <h3>Address: {contactData.address}</h3>
                <h3>Phone : {contactData.contact_number}</h3>
                <h3>Email : {contactData.email}</h3>
                <h3>Reach Us : 24 Hours</h3>

                <div className={style.Social}>
                  <h1>Socials</h1>
                  <hr className={style.ContactHR} />
                  <div className={style.SocialIcon}>
                    {Array.isArray(socialMediaData) &&
                      socialMediaData.map((smData, i) => {
                        return (
                          <Link to={smData.link} target="_blank">
                            <MediaIcon iconName={smData.platform} />
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
