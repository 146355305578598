import React, { useEffect, useState } from "react";
import "./AdminDashBoard.css";
import DashTheme1 from "../../../Container/DashBoard/DashTheme1/DashTheme1";
import DashTheme2 from "../../../Container/DashBoard/DashTheme2/DashTheme2";

const AdminDashBoard = () => {
  return (
    <div className="DashBoardWrapper">
      <div className="DashBoardContainer">
        <h1>Dashboard</h1>
        {/* <DashTheme1 /> */}
        <DashTheme2 />
      </div>
    </div>
  );
};

export default AdminDashBoard;
