import "./App.css";
import { Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ClientLayout from "./Components/ClientLayout/ClientLayout";
import Homepage from "./Pages/Homepage/Homepage";
import Login from "./Pages/Login/Login";
import Comming from "./Pages/CommingSoon/Comming";
import Register from "./Pages/Register/Register";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import Experiments from "./Pages/Experiments/Experiments";
import { useEffect, useState } from "react";
import IndividualCategory from "./Pages/IndividualCategory/IndividualCategory";
import BookingPage from "./Pages/BookingPage/BookingPage";
import MyBookings from "./Pages/MyBookings/MyBookings";
// import RatesPage from "./Pages/RatesPage/RatesPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import AboutUsPage from "./Pages/AboutUsPage/AboutUsPage";
import ReservationPage from "./Pages/ReservationPage/ReservationPage";
import AdminLayout from "./Components/AdminLayout/AdminLayout";
import ViewCar from "./Pages/Admin/ViewCar/ViewCar";
import AddCar from "./Pages/Admin/AddCar/AddCar";
import AdminDashBoard from "./Pages/Admin/AdminDashBoard/AdminDashBoard";
import AddCategory from "./Pages/Admin/AddCategory/AddCategory";
import ViewAdmin from "./Pages/Admin/ViewAdmin/ViewAdmin";
import AddAdmin from "./Pages/Admin/AddAdmin/AddAdmin";
import ViewBookings from "./Pages/Admin/ViewBookings/ViewBookings";
import AdminLogin from "./Pages/Admin/AdminLogin/AdminLogin";
import UpdateCategory from "./Pages/Admin/UpdateCategory/UpdateCategory";
import UpdateAdmin from "./Pages/Admin/UpdateAdmin/UpdateAdmin";
import EditCar from "./Pages/Admin/AddCar/EditCar";
import ViewCategory from "./Pages/Admin/ViewCategory/ViewCategory";
import ViewContacts from "./Pages/Admin/VIewContacts/ViewContacts";
import MyBookedView from "./Pages/MyBookedView/MyBookedView";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "./Pages/PaymentFailed/PaymentFailed";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import SettingLayout from "./Components/SettingLayout/SettingLayout";
import ThemeContainer from "./Pages/Admin/Settings/ThemeContainer/ThemeContainer";
import AboutContainer from "./Pages/Admin/Settings/AboutContainer/AboutContainer";
import ContactInfoContainer from "./Pages/Admin/Settings/ContactInfoContainer/ContactInfoContainer";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import CoverImageContainer from "./Pages/Admin/Settings/CoverImageContainer/CoverImageContainer";
import CategoryState from "./Context/Category/CategoryState";
import BookingState from "./Context/Booking/BookingState";
import CarReportState from "./Context/CarReport/CarReportState";
import SuccessPaymentInvoice from "./Pages/PaymentSuccess/SuccessPaymentInvoice";
import NotificationState from "./Context/Notification/NotificationState";
import StripeContainer from "./Pages/Admin/Settings/StripeContainer/StripeContainer";
import PrivacyPolicy from "./Pages/PrivacyPolicyPage/PrivacyPolicy";
import PrivacyContainer from "./Pages/Admin/Settings/PrivacyContainer.jsx/PrivacyContainer";
import ServiceContainer from "./Pages/Admin/Settings/serviceContainer/ServiceContainer";

function App() {
  let token = localStorage.getItem("access_token_cb");
  const [userData, setUserData] = useState("");
  const [authUser, setAuthUser] = useState(null);

  //  Hide ResizeObserver loop limit exceeded error if it occours.

  // passport JS
  // const getUser = () => {
  //   fetch(`${process.env.REACT_APP_API_URL}auth/login/success`, {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Credentials": true,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.status === 200) return response.json();
  //       throw new Error("authentication has been failed!");
  //     })
  //     .then((resObject) => {
  //       setAuthUser(resObject.user);
  //       localStorage.setItem("authKey", JSON.stringify(resObject.user));
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //     });
  // };

  const forUserVerification = () => {
    //this is for some random browser error
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });

    // getUser();

    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    }
  };

  useEffect(() => {
    forUserVerification();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    forUserVerification();
    // eslint-disable-next-line
  }, [token]);

  return (
    <CategoryState>
      <BookingState>
        <NotificationState>
          <CarReportState>
            <Routes>
              {/* these are unprotected routes */}
              <Route path="/" element={<ClientLayout />}>
                <Route path="/" element={<Homepage />} />
                {/* <Route path="/rates_details" element={<RatesPage />} /> */}
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset" element={<ForgetPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route
                  path="/myBookings/:bookedID"
                  element={<MyBookedView />}
                />
                <Route
                  path="/invoice/:bookedID"
                  element={<SuccessPaymentInvoice />}
                />
                <Route
                  path="/category/:category"
                  element={<IndividualCategory />}
                />
                <Route path="/booking/:carid" element={<BookingPage />} />
                <Route path="/reservation" element={<ReservationPage />} />
                <Route
                  path="/pay/success/:bookedID"
                  element={<PaymentSuccess />}
                />

                <Route
                  path="/pay/failed/:bookedID"
                  element={<PaymentFailed />}
                />
                <Route path="/services" element={<Comming />} />
                <Route path="*" element={<PageNotFound />} />

                {(userData.role || authUser) && (
                  <>
                    <Route path="/myBookings" element={<MyBookings />} />
                    <Route
                      path="/changepassword"
                      element={<ChangePassword />}
                    />
                  </>
                )}
              </Route>

              <Route path="/adminLogin" element={<AdminLogin />} />
              {userData.role === "admin" || userData.role === "superAdmin" ? (
                <Route path="/admin" element={<AdminLayout />}>
                  <Route path="dash" element={<AdminDashBoard />}></Route>
                  <Route path="car" element={<ViewCar />}></Route>
                  <Route path="car/addCar" element={<AddCar />}></Route>
                  <Route path="car/:carID" element={<EditCar />}></Route>
                  <Route path="category" element={<ViewCategory />}></Route>
                  <Route path="category/add" element={<AddCategory />}></Route>
                  <Route
                    path="category/:categoryID"
                    element={<UpdateCategory />}
                  ></Route>
                  <Route path="contact" element={<ViewContacts />}></Route>
                  <Route path="ViewBookings" element={<ViewBookings />}></Route>
                  {userData.role === "superAdmin" && (
                    <>
                      <Route path="view" element={<ViewAdmin />}></Route>
                      <Route path="view/add" element={<AddAdmin />}></Route>
                      <Route
                        path="updateAdmin/:adminID"
                        element={<UpdateAdmin />}
                      ></Route>
                      <Route path="settings" element={<SettingLayout />}>
                        <Route path="about" element={<AboutContainer />} />
                        <Route
                          path="contact"
                          element={<ContactInfoContainer />}
                        />
                        <Route
                          path="stripe"
                          element={<StripeContainer />}
                        />
                        <Route path="theme" element={<ThemeContainer />} />
                        <Route
                          path="coverImage"
                          element={<CoverImageContainer />}
                        />
                         <Route
                          path="privacy-policy"
                          element={<PrivacyContainer />}
                        />
                        <Route
                          path="services"
                          element={<ServiceContainer />}
                        />
                      </Route>
                    </>
                  )}
                </Route>
              ) : (
                <Route path="/test" element={<Experiments />}></Route>
              )}
            </Routes>
          </CarReportState>
        </NotificationState>
      </BookingState>
    </CategoryState>
  );
}

export default App;
