import React, { useEffect, useState } from "react";
import "./AboutContainer.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HTMLReactParser from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BtnToClick from "../../../../Components/BtnToClick/BtnToClick";
import { getAllData, updateData } from "../../../../constants/apiService";

export default function AboutContainer() {
  const [editContent, setEditContent] = useState(false);
  const [ckEditorContent, setCkEditorContent] = useState("");
  const [aboutDataId, setAboutDataId] = useState("");

  const fetchAbout = async () => {
    try {
      const url = "/about/getallabout";
      const result = await getAllData(url);
      if (result.status === 200) {
        const resData = result.data.data[0].description;
        setCkEditorContent(resData);
        setAboutDataId(result.data.data[0]._id);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };

  useEffect(() => {
    fetchAbout();
  }, []);

  const editAbout = () => {
    if (editContent) {
      setEditContent(false);
    } else {
      setEditContent(true);
    }
  };

  const onSubmitClick = async (e) => {
    e.preventDefault();
    const url = "about/editabout/" + aboutDataId;
    let menuItemData = new FormData();
    menuItemData.append("description",ckEditorContent);

    try {
      const result = await updateData(url, menuItemData);
      if (result.status === 200 || result.status === 201) {
        toast.success("About Us updated successfully.");
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
    setEditContent(false);
  };
  return (
    <div className="aboutA_wrapper">
      <div className="aboutA_container">
        <h1>About Us</h1>
        <ToastContainer />
        <div
          className="aboutA_btn"
          onClick={() => {
            editAbout();
          }}
        >
          <BtnToClick title={editContent ? "Cancel" : "Edit"} />
        </div>

        <div className="aboutA_content">
          {!editContent ? (
            <div className="viewAboutContent">
              {HTMLReactParser(ckEditorContent)}
            </div>
          ) : (
            <div className="editAboutContent">
              <CKEditor
                editor={ClassicEditor}
                id="description"
                data={ckEditorContent}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setCkEditorContent(data);
                }}
              />
              <div className="aboutA_btn" onClick={onSubmitClick}>
                <BtnToClick title="Update" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
