import React, { useEffect, useState } from "react";
import style from "./ChangePassword.module.css";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let token = localStorage.getItem("access_token_cb");
  const [userData, setUserData] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = `/user/changePassword/${userData?.id}`;

    const changePasswordData = {
      oldPassword: enterdData.oldPassword,
      newPassword: enterdData.newPassword,
      confirmPassword: enterdData.reNewPassword,
    };
    const response = await storeData(url, changePasswordData);

    if (response.status === 200) {
      setLoading(false);
      toast.success("Password Changed Sucessfully");
      localStorage.removeItem("access_token_cb");
      navigate("/login");
    } else {
      setLoading(false);
      toast.error(response.response.data.message);
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to show and hide password
  // attributes are InputID, showSVGIconID, hideSVGIconID
  const onPasswordShow = (inputId, showSVG, hideSVG) => {
    const passwordForm = document.getElementById(inputId);
    const showPassSVG = document.getElementById(showSVG);
    const hidePassSVG = document.getElementById(hideSVG);
    const passwordAttr = passwordForm.getAttribute("type");
    if (passwordAttr === "password") {
      passwordForm.setAttribute("type", "text");
      showPassSVG.style.display = "none";
      hidePassSVG.style.display = "block";
    } else {
      passwordForm.setAttribute("type", "password");
      showPassSVG.style.display = "block";
      hidePassSVG.style.display = "none";
    }
  };

  return (
    <>
      {loading ? (
        <div className={style.LoginWrapper}>
          <h1>... Changing Password ... </h1>
        </div>
      ) : (
        <div className={style.LoginWrapper}>
          <h1>Change Password</h1>
          <form
            onSubmit={handleSubmit}
            className={style.FormWrapper}
            autoComplete="off"
          >
            <div className={style.passwordInputDiv}>
              <h2>Old Password</h2>
              <input
                type="password"
                name="oldPassword"
                id="oldPasswordInput"
                required
              ></input>
              <div className={style.passwordShower}>
                <AiFillEye
                  id="oldPasswordShow_icon"
                  onClick={() => {
                    onPasswordShow(
                      "oldPasswordInput",
                      "oldPasswordShow_icon",
                      "oldPasswordHide_icon"
                    );
                  }}
                />
                <AiFillEyeInvisible
                  id="oldPasswordHide_icon"
                  onClick={() => {
                    onPasswordShow(
                      "oldPasswordInput",
                      "oldPasswordShow_icon",
                      "oldPasswordHide_icon"
                    );
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className={style.passwordInputDiv}>
              <h2>New Password</h2>
              <input
                type="password"
                name="newPassword"
                id="newPasswordInput"
                required
              ></input>
              <div className={style.passwordShower}>
                <AiFillEye
                  id="newPasswordShow_icon"
                  onClick={() => {
                    onPasswordShow(
                      "newPasswordInput",
                      "newPasswordShow_icon",
                      "newPasswordHide_icon"
                    );
                  }}
                />
                <AiFillEyeInvisible
                  id="newPasswordHide_icon"
                  onClick={() => {
                    onPasswordShow(
                      "newPasswordInput",
                      "newPasswordShow_icon",
                      "newPasswordHide_icon"
                    );
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className={style.passwordInputDiv}>
              <h2>Retype New Password</h2>
              <input
                type="password"
                name="reNewPassword"
                id="reNewPasswordInput"
                required
              ></input>
              <div className={style.passwordShower}>
                <AiFillEye
                  id="reNewPasswordShow_icon"
                  onClick={() => {
                    onPasswordShow(
                      "reNewPasswordInput",
                      "reNewPasswordShow_icon",
                      "reNewPasswordHide_icon"
                    );
                  }}
                />
                <AiFillEyeInvisible
                  id="reNewPasswordHide_icon"
                  onClick={() => {
                    onPasswordShow(
                      "reNewPasswordInput",
                      "reNewPasswordShow_icon",
                      "reNewPasswordHide_icon"
                    );
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <button>Change Password</button>
          </form>
        </div>
      )}
    </>
  );
};

export default ChangePassword;
