import React, { useState } from "react";
import BookingContext from "./BookingContext";
import { getAllFormData } from "../../constants/apiService";
import { toast } from "react-toastify";

const BookingState = (props) => {
  const [bloading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalCompletedService, setTotalCompletedService] = useState(0);
  const [totalRemainingService, setTotalRemainingService] = useState(0);

  const fetchBookingData = async () => {
    setLoading(true);
    try {
      const url = "/bookings/getAll";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let cars = result.data;
        setBookingData(cars.data.reverse());
        totalEarningCalc(cars.data);
        totalCompletedServiceFunc(cars.data);
        totalRemainingServiceFunc(cars.data);
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const totalEarningCalc = (dataz) => {
    let totalEarning = 0;
    if (dataz) {
      dataz.map((data, i) => {
        if (data.cancelStatus !== "Cancelled") {
          totalEarning = totalEarning + data.cost;
        }
        setTotalEarning(totalEarning);
      });
    } else {
      console.log("data not found yet");
    }
  };

  const totalCompletedServiceFunc = (carData) => {
    let result = carData.filter(
      (booked) => booked.cancelStatus === "Completed"
    );
    setTotalCompletedService(result.length);
  };

  const totalRemainingServiceFunc = (carData) => {
    let result = carData.filter((booked) => booked.cancelStatus === "null");
    setTotalRemainingService(result.length);
  };

  return (
    <BookingContext.Provider
      value={{
        fetchBookingData,
        totalEarning,
        bloading,
        bookingData,
        totalCompletedService,
        totalRemainingService,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};

export default BookingState;
