import React, { useEffect, useState } from "react";
import style from "../Login/Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { updateData } from "../../constants/apiService";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isAlreadyLoggedIn = localStorage.getItem("access_token_cb");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = "/user/reset";

    const forgotPasswordData = {
      email: enterdData.Email,
    };
    const response = await updateData(url, forgotPasswordData);

    if (response.data) {
      setLoading(false);
      toast.success("Check your email for the new password.");
      navigate("/login");
      // setLoadings(true)
    } else {
      setLoading(false);
      toast.error(response.response.data.error);
      // setLoadings(false)
    }
  };

  useEffect(() => {
    if (isAlreadyLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.LoginWrapper}>
          <div className={style.WaitingMessage}>
            <h1>... Authenticating ...</h1>
          </div>
        </div>
      ) : (
        <div className={style.LoginWrapper}>
          <h1>Forgot Password</h1>
          <form
            onSubmit={handleSubmit}
            className={style.FormWrapper}
            autoComplete="off"
          >
            <h2>Email</h2>
            <input
              type="email"
              className={style.resetPassword}
              required
              name="Email"
            ></input>

            <button>Reset Password</button>
            <hr style={{ margin: "10px 0px" }} />

            <Link to={"/login"}>
              <h3>Back to SignIn</h3>
            </Link>
          </form>
        </div>
      )}
    </>
  );
}
