import React from "react";
import style from "./Flex2item.module.css";
import { Link } from "react-router-dom";
import { MdSettings } from "react-icons/md";

export default function Flex2item({
  type,
  leftText,
  rightText,
  leftURL,
  rightURL,
  onCLickLeftBTN,
  onCLickRightBTN,
}) {
  return (
    <>
      {type === "text" && (
        <div className={style.perItem}>
          <div className={style.perItem_left}>{leftText}</div>
          <div className={style.perItem_right}>{rightText}</div>
        </div>
      )}

      {type === "date" && (
        <div className={style.perItem}>
          <div className={style.perItem_left}>From : {leftText}</div>
          <div className={style.perItem_rightDate}>To : {rightText}</div>
        </div>
      )}

      {type === "image" && (
        <div className={style.perItem}>
          <div className={style.perItem_rightImage}>
            <h2>{rightText}</h2>
          </div>
          <div className={style.perItem_leftImage}>
            <img
              src={`${leftURL}`}
              alt=""
            />
          </div>
        </div>
      )}

      {type === "button" && (
        <div className={style.perItem}>
          <div className={style.dbperItem_left}>
            <Link to={leftURL} onClick={onCLickLeftBTN}>
              <div className={style.perItem_leftBTN}>{leftText}</div>
            </Link>
          </div>
          <div className={style.dbperItem_right}>
            <Link to={rightURL}>
              <div className={style.perItem_rightBTN}>{rightText}</div>
            </Link>
          </div>
        </div>
      )}

      {type === "singlebutton" && (
        <div className={style.perItem}>
          <div className={style.perItem_middle}>
            <Link to={rightURL} onClick={onCLickRightBTN}>
              <div className={style.perItem_middleBTN}>
                {rightText === "Loading" ? (
                  <MdSettings className="loading-icon" />
                ) : (
                  rightText
                )}
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
