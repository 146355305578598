import React, { useEffect, useState } from "react";
import style from "./ViewCategory.module.css";
import { MdDeleteSweep, MdOutlineModeEditOutline } from "react-icons/md";

import { Link } from "react-router-dom";
import { deleteById, getAllFormData } from "../../../constants/apiService";
import { Button, Space, Tooltip, Popconfirm } from "antd";

// import toast from "react-hot-toast";
import { toast } from "react-toastify";

export default function ViewCategory() {
  const [datas, setDatas] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const url = "/category/getallcategory";

      const result = await getAllFormData(url);

      if (result.status === 200) {
        let category = result.data;
        setDatas(category);
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const handleDelete = async (id) => {
    if (id) {
      const url = `/category/deletecategory/${id}`;
      const result = await deleteById(url);
      if (result.response.status === 400) {
        toast.success("category Deleted Sucessfully");
        fetchAll();
      } else if (result.response.status === 404) {
        toast.error(result.response.statusText);
      } else if (result.response.status === 200) {
        toast.success("Damn Finally you came");
        // toast.error(result.response.data.errors[0].message);
      } else {
        toast.error(result.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.loadingTextDiv}>
          <h1 className={style.loadingText}>... Loading ...</h1>
        </div>
      ) : (
        <div className={style.ViewCarTableWrapper}>
          <div className={style.ViewCarTable}>
            <div className={style.FormHeading}>
              <div className={style.FH_left}>
                <Link to="/admin/category/add" className={style.baseBTN}>
                  Add Category
                </Link>
              </div>
              <div className={style.FH_right}>
                <h1>Category List</h1>
              </div>
            </div>
            <table className={style.VCTable}>
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>SN</th>
                  <th style={{ width: "20%" }}>Category Title</th>
                  <th style={{ width: "35%" }}>Description</th>
                  <th style={{ width: "20%" }}>Image</th>
                  <th style={{ width: "20%" }}>Actions</th>
                </tr>
                {datas &&
                  datas.map((data, i) => {
                    return (
                      <tr key={data._id}>
                        <td>{i + 1}</td>
                        <td>{data.title}</td>
                        <td>
                          <h2>{data.description}</h2>
                        </td>
                        <td>
                          <img
                            className=""
                            src={
                              data.imageUrl
                            }
                            alt="Id proof"
                            width="60px"
                          />
                        </td>
                        <td>
                          <Space size="middle">
                            <Link
                              className="mr-1"
                              size="small"
                              style={{ fontSize: "22px", padding: "0 10px" }}
                              to={`/admin/category/` + data._id}
                            >
                              <MdOutlineModeEditOutline
                                style={{ color: "green" }}
                              />
                            </Link>

                            <Tooltip placement="bottomRight" title="Delete">
                              <Popconfirm
                                title="Delete record?"
                                onConfirm={() => handleDelete(data._id)}
                              >
                                <Button
                                  className="mr-1"
                                  style={{
                                    fontSize: "22px",
                                    padding: "0 10px",
                                  }}
                                  size="small"
                                >
                                  <MdDeleteSweep style={{ color: "red" }} />
                                </Button>
                              </Popconfirm>
                            </Tooltip>
                          </Space>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
