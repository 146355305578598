import React from "react";
import "./CoverImageContainer.css";
import HeroEditable from "../../../../Components/Hero/HeroEditable";
import { storeFormData } from "../../../../constants/apiService";
import { useState } from "react";
import { toast } from "react-toastify";

export default function CoverImageContainer() {
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);

  const handleFileChange = (e) => {
    setVideo(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!video) {
      toast.error("Please select a video file");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('videoUrl', video);

    try {
      const result = await storeFormData("/image/uploadVideo", formData);

      if (result.status === 201) {
        toast.success("Video uploaded successfully");
      } else if (result.response.status === 401) {
        toast.error(result.response.error);
      } else if (result.response.status === 400) {
        toast.error(result.response.data.errors[0].message);
      } else {
        toast.error(result.response.data.reason);
      }
    } catch (error) {
      toast.error("An error occurred while uploading the video");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="CoverImageWrapper">
      <div className="CoverImageContainer">
        <h3>Cover Image</h3>

        <div className="CoverImageTheme">
          <div className="CoverImageThemeType1">
            <HeroEditable />
          </div>
        </div>
        <div className="coverVideoUploadWrapper">
        <h3>Cover Video Upload</h3>
        <form onSubmit={handleSubmit}>
          <input type="file" name="videoUrl" accept="video/*" onChange={handleFileChange} />
          <button type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Save"}
          </button>
        </form>
        </div>
        
      </div>
    </div>
  );
}
