import React, { useState } from "react";
import style from "./ContinueAsGuest.module.css";
import moment from "moment";
import { storeData } from "../../constants/apiService";
// import { toast } from "react-hot-toast";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const ContinueAsGuest = (props) => {
  const { carid, bookingData, luggageData, passengerData, carData, checkout } =
    props;
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let enterdData = bookingData;

    const serviceType=enterdData?.serviceType
    const startTime = enterdData?.pickupTime;
    const endTime = enterdData?.DropOffTime;
    const startDate = moment(enterdData?.pickupDate).format("MM/DD/YYYY");
    const endDate = moment(enterdData?.DropOffDate).format("MM/DD/YYYY");
    const from = enterdData?.pickupLocation;
    const to = enterdData?.DropOffLocation;
    const passengers = passengerData;
    const luggageCount = luggageData;

    const data = new FormData(e.target);
    let enterdCredentials = Object.fromEntries(data.entries());

    try {
      const result = await storeData("/bookings/bookasguest", {
        isGuest: true,
        email: enterdCredentials?.email,
        fullName: enterdCredentials?.fullname,
        phone: enterdCredentials?.contactNumber,
        carId: carid,
        from: from,
        to: to,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        passengers: passengers,
        luggageCount: luggageCount,
        serviceType: serviceType,
      });

      if (result.status === 200) {
        const carImage = carData.imageUrl;
        checkout(result.data.booking, carImage);
        setLoading(false);
        // toast.success("Car booked sucessfully");
        // navigate("/contact");
      } else if (result.status === 509) {
        setLoading(false);

        toast.error(result.message);
      } else if (result.status === 404) {
        setLoading(false);

        toast.error(result.statusText);
      } else if (result.status === 400) {
        setLoading(false);

        toast.error(result.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.message);
      }
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };

  return (
    <div className={style.GuestWrapper}>
      <h1>Continue As guest</h1>
      <form
        onSubmit={onSubmit}
        className={style.GuestFormWrapper}
        autoComplete="off"
      >
        <h2>Full Name</h2>
        <input
          type="text"
          required
          name="fullname"
          aria-label="Full Name"
          autoComplete="name"
        ></input>
        <h2>Email</h2>
        <input
          type="email"
          required
          name="email"
          aria-label="Email"
          autoComplete="Email"
        ></input>
        <h2>Contact Number</h2>
        <input
          type="text"
          name="contactNumber"
          required
          aria-label="Contact number"
          autoComplete="tel"
        />

        {loading ? <button>.. Loading ..</button> : <button>Submit</button>}
      </form>
    </div>
  );
};

export default ContinueAsGuest;
