import React, { useEffect, useState } from "react";
import "./SecondarySideBar.css";
import { Link, NavLink } from "react-router-dom";
import { MdAdminPanelSettings, MdKeyboardBackspace } from "react-icons/md";

import { SettingsNavLinks } from "../../Data/SettingsNavLinks";

export default function SecondarySideBar() {
  const hideSecondarySideBar = () => {
    const secSideBarId = document.getElementById("SecondarySideBarWrapper");
    secSideBarId.classList.add("hideSideBar");
  };

  return (
    <div className="SecondarySideBarWrapper" id="SecondarySideBarWrapper">
      <div className="SecondarySideBar">
        <NavLink className="PrimaryTitle" onClick={hideSecondarySideBar}>
          <h1>
            <MdKeyboardBackspace /> <span> Configure</span>
          </h1>
        </NavLink>
        <div className="SecondaryDashboardLinks">
          {SettingsNavLinks.map((link, key) => {
            return (
              <NavLink to={link.Linkto} key={key}>
                <h1 key={key}>
                  {link.Logo} <span> {link.Title}</span>
                </h1>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}
