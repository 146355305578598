import React from "react";
import style from "./Footer.module.css";
import Logo from "../../assets/actual_logo.jpg";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { getAllData, getAllFormData } from "../../constants/apiService";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom/dist";
import MediaIcon from "../MediaIcon/MediaIcon";
import { toast } from "react-toastify";
import uuid from "react-uuid";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState("");
  const [socialMediaData, setSocialMediaData] = useState();
  const [contactData, setContactData] = useState({
    name: "-",
    contact_number: "-",
    address: "-",
    email: "-",
  });

  const dummyArr = [0, 1, 2];

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const url = "/contactinfo/getallcontactinfo/";
      const result = await getAllData(url);
      if (result.status === 200) {
        const collectedData = result.data.data[0];
        setContactData({
          name: collectedData.name,
          contact_number: collectedData.phone_number,
          address: collectedData.address,
          email: collectedData.email,
        });
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  const fetchAllCategory = async () => {
    setLoading(true);
    try {
      const url = "category/getallcategory";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let categories = await result.data;
        setCategories(categories);
        setLoading(false);
      } else if (result.status === 404) {
        // toast.error("Some error occurred");
        setLoading(false);
      } else if (result.status === 400) {
        // toast.error("Some error occurred");
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSocialMediaData = async () => {
    setLoading(true);
    try {
      const url = "/social/getallsocial/";
      const result = await getAllData(url);
      if (result.status === 200) {
        setSocialMediaData(result.data.data);
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategory();
    fetchContactData();
    fetchSocialMediaData();
  }, []);

  return (
    <>
      <hr className={style.footerHR} />
      {loading && (
        <>
          <div className={style.FooterWrapper}>
            <div className={style.Footer}>
              <div className={style.SkelWrapper}>
                {dummyArr.map(() => (
                  <div key={uuid()} className={style.Training}>
                    <div className={style.Skel}>
                      <div className={style.H2Div}></div>
                      {/* <div className={style.H1Div}></div> */}
                      <div className={style.imgDiv}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr className={style.footerHR} />
          <div className={style.FotoerDisclaimer}>
            <div key={uuid()} className={style.Training}>
              <div className={style.Skel}>
                <div className={style.H1Div}></div>
              </div>
            </div>
          </div>
        </>
      )}

      {categories && socialMediaData && contactData && !loading && (
        <>
          <div className={style.FooterWrapper}>
            <div className={style.Footer}>
              <div className={style.Left}>
                <img src={Logo} alt="Company Logo"></img>
                <h1>NTX Limo </h1>
                <ul>
                  <li>{contactData.address}</li>
                  <li>{contactData.contact_number}</li>
                  <li>{contactData.email}</li>
                </ul>
              </div>
              <div className={style.Mid}>
                <div className={style.Cat}>
                  <h1>Category</h1>
                  <ul>
                    {Array.isArray(categories) &&
                      categories.map((category, i) => {
                        return (
                          <Link to={`/category/${category.title}`} key={i}>
                            <li key={i}>{category.title}</li>
                          </Link>
                        );
                      })}
                  </ul>
                </div>
                <div className={style.Social}>
                  <h1>Socials</h1>
                  <div className={style.SocialIcon}>
                    {Array.isArray(socialMediaData) &&
                      socialMediaData.map((smData, i) => {
                        return (
                          <Link to={smData.link} target="_blank">
                            <MediaIcon iconName={smData.platform} />
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={style.Right}>
                <div className={style.Branch}>
                  <h1>Branch</h1>
                  <ul>
                    <li>USA</li>
                  </ul>
                </div>
                <div className={style.Duration}>
              <h1>Information</h1>
              <ul>
                <Link to={`/privacy-policy`} key={0}>
                            <li key={0}>Privacy Policy</li>
                          </Link>
              </ul>
            </div>
              </div>
            </div>
          </div>
          <hr className={style.footerHR} />
          <div className={style.FotoerDisclaimer}>
            <h1>
              This site and all original text, imagery, media and other content
              contained within is under &copy; 2002-23 by ntxlimo.com(NTX Lino
              Inc.). unauthorized reproduction or publishing of these material
              is strictely prohibited, and punisiable to full extent of the law.
            </h1>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
