import React, { useEffect, useState } from "react";

import style from "./MyBookedView.module.css";

import { getDataById } from "../../constants/apiService";
import moment from "moment/moment";
import { Link, useParams } from "react-router-dom";
import uuid from "react-uuid";

import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

export default function MyBookedView() {
  const { bookedID } = useParams();
  const [myBookings, setMyBookings] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);

  const dummyArr = [0, 1, 2];

  //fetching car details
  const fetchBookings = async () => {
    try {
      const url = `/bookings/getbookingbyid/${bookedID}`;
      const result = await getDataById(url);

      if (result.status === 200) {
        setLoading(false);
        setMyBookings(result.data.data);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const handleStatusChange = async (value, recordItem) => {
    setStatusLoading(true);
    let statusData = {
      status: value,
    };
    const url = `/bookings/${recordItem}`;
    const result = await storeData(url, statusData);
    if (result.status === 200 && value === "Pending") {
      toast.success(`Rerservation Cancellation is requested.`);
      setStatusLoading(false);
    } else {
      toast.success(`Rerservation requested again.`);
      setStatusLoading(false);
    }
    fetchBookings();
  };

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={style.MyBookingsWrapper}>
        <div className={style.Heading}>
          <h1>My Booking</h1>
        </div>
        {loading ? (
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={style.BookingLists}>
            {myBookings ? (
              <div
                className={style.IndividualBookingDetail}
                key={myBookings._id}
              >
                <div className={style.InfoArea}>
                  <img
                    src={
                      myBookings.car
                        ? `${myBookings.car.imageUrl}`
                        : "https://img.freepik.com/premium-vector/car-cartoon-vehicle-transportation-isolated_138676-2473.jpg?w=2000"
                    }
                    alt="car"
                  ></img>
                  <div className={style.BookingDetailTexts}>
                    <h1>
                      {myBookings.car
                        ? myBookings.car.name
                        : "Unknown Car Name"}
                    </h1>

                    <div className={style.DataTable}>
                      <div className={style.TableHeading}>
                        <h2>Starting Date</h2>
                        <h2>Starting Time</h2>
                        <h2>PickUp Location</h2>
                        <h2>Ending Date</h2>
                        <h2>Ending Time</h2>
                        <h2>Drop Location</h2>
                        <h2>Cost</h2>
                      </div>
                      <div className={style.TableData}>
                        <h2>
                          {moment(myBookings?.startTime).format("YYYY-MM-DD")}
                        </h2>
                        <h2>
                          {moment(myBookings?.startTime).format("hh:mm:A")}
                        </h2>
                        <h2>{myBookings?.from}</h2>
                        <h2>
                          {moment(myBookings?.endTime).format("YYYY-MM-DD")}
                        </h2>
                        <h2>{moment(myBookings?.endTime).format("hh:mm:A")}</h2>
                        <h2>{myBookings?.to}</h2>
                        <h2>{myBookings?.cost.toFixed(2)}</h2>
                      </div>
                    </div>

                    <div className={style.MoreInfo}>
                      <h3 className={style.mi_one}>
                        Passenger Capcity :{" "}
                        {myBookings.passengers ? myBookings.passengers : "0"}
                      </h3>
                      <h3 className={style.mi_two}>
                        Luggage Capacity :{" "}
                        {myBookings.luggageCount
                          ? myBookings.luggageCount
                          : "0"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className={style.ActionButtons}>
                  {statusLoading ? (
                    <h2 className={style.statusLoadingH}>... Loading ...</h2>
                  ) : (
                    <>
                      <div className={style.ActionBTN_left}>
                        <button value={myBookings?.cancelStatus}>
                          Cancel Status : {myBookings?.cancelStatus}
                        </button>
                      </div>
                      <div className={style.ActionBTN_right}>
                        {myBookings.cancelStatus === "Pending" && (
                          <button
                            className={style.aBTNrevert}
                            value={myBookings._id}
                            onClick={(e) => {
                              e.preventDefault();
                              handleStatusChange("null", myBookings._id);
                            }}
                          >
                            Revert Decision
                          </button>
                        )}

                        {myBookings.cancelStatus === "null" && (
                          <div
                            className={style.statusActionBTN}
                            style={{ display: "flex", float: "right" }}
                          >
                            <Link to={`/invoice/${myBookings._id}`}>
                              <button
                                className={style.printBTN}
                                value={myBookings._id}
                              >
                                Print
                              </button>
                            </Link>
                            <button
                              className={style.cancelBTN}
                              value={myBookings._id}
                              onClick={(e) => {
                                e.preventDefault();
                                handleStatusChange("Pending", myBookings._id);
                              }}
                            >
                              Cancel Reservation
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className={style.NoBookingContainer}>
                <h1 className="mt-4">!! You have not BOOKED anything !!</h1>
                <Link to={"/reservation"} className={style.NoBookBTN}>
                  BOOK NOW
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
