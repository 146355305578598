import React from "react";
import style from "./AdminBookingTable.module.css";
import { Select } from "antd";
import {
  MdKeyboardDoubleArrowDown,
  MdCancel,
  MdVerified,
  MdDelete,
  MdOutlineCurrencyExchange,
} from "react-icons/md";
import moment from "moment";
import { deleteById, storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

export default function AdminBookingList(props) {
  const { data, i, role, fetchDataFun } = props;

  const handleCancelStatus = (statusProp) => {
    if (statusProp === "Pending") {
      return {
        backgroundColor: "#937e47",
        border: "2px solid var(--text-color)",
        color: "white",
      };
    } else if (statusProp === "Cancelled") {
      return {
        backgroundColor: "#4f4934",
        border: "2px solid var(--text-color)",
        color: "white",
      };
    } else if (statusProp === "Refunded") {
      return {
        backgroundColor: "#4f4934",
        border: "2px solid var(--text-color)",
        color: "white",
      };
    }
  };

  const handleChange = async (value, recordItem) => {
    let statusData = {
      status: value,
    };

    const url = `/bookings/byadmin/${recordItem}`;
    const result = await storeData(url, statusData);
    if (result.status === 200) {
      fetchDataFun();
      toast.success(`Booking status changed to ${value} sucessfully`);
    }
  };

  const deleteBookedDetails = async (bookedID_data) => {
    const url = `/bookings/removebooking/${bookedID_data}`;
    const result = await deleteById(url);
    if (result.status === 200) {
      fetchDataFun();
      toast.success("Data deleted. Please refresh to preview new data");
    } else {
      toast.error("Error or is already deleted");
    }
  };

  return (
    <>
      <tr key={data?._id} style={handleCancelStatus(data.cancelStatus)}>
        <td className={style.dataKeyValue}>
          <span>{i + 1}</span>
          {role === "superAdmin" ? (
            <span className={style.dataValueDelete}>
              <MdDelete
                onClick={() => {
                  deleteBookedDetails(data._id);
                }}
              />
            </span>
          ) : (
            <span>{i + 1}</span>
          )}
        </td>
        {data.isGuest ? (
          <td>
            {data.guestUser ? data.guestUser.fullName : "null"}
            <br />
            {data.guestUser ? data.guestUser.phone : "null"}
          </td>
        ) : (
          <td>
            {data.user ? data.user.fullName : "null"}
            <br />
            {data.user ? data.user.email : "null"}
          </td>
        )}
        <td>{data.car ? data.car.name : "null"}</td>
        <td>{data.cost ? data.cost.toFixed(2) : "null"}</td>
        <td>
          {data ? moment(data.startTime).format("MMMM Do YYYY") : "null"}
          <br />
          <MdKeyboardDoubleArrowDown />
          <br />
          {data ? moment(data.endTime).format("MMMM Do YYYY") : "null"}
        </td>
        <td>
          {data ? moment(data.startTime).format("h : mm a") : "null"}
          <br />
          <MdKeyboardDoubleArrowDown />
          <br />
          {data ? moment(data.endTime).format("h : mm a") : "null"}
        </td>
        <td>
          {data ? data.from : "null"}
          <br />
          <MdKeyboardDoubleArrowDown />
          <br />
          {data ? data.to : "null"}
        </td>
        <td>{data.car ? data.serviceType : "null"}</td>
        <td>
          {data.cancelStatus === "Refunded" ? (
            <MdOutlineCurrencyExchange
              style={{ color: "red", fontSize: "25px" }}
            />
          ) : data.paymentStatus ? (
            <MdVerified style={{ color: "green", fontSize: "25px" }} />
          ) : (
            <MdCancel style={{ color: "red", fontSize: "25px" }} />
          )}
        </td>
        {role === "superAdmin" ? (
          <td>
            <Select
              defaultValue={
                data.cancelStatus === "null" ? " --- " : data.cancelStatus
              }
              style={{
                width: "80%",
              }}
              onChange={(value) => handleChange(value, data._id)}
              options={[
                {
                  value: "Completed",
                  label: "Service Complete",
                },
                {
                  value: "Pending",
                  label: "Cancel Request",
                },
                {
                  value: "Cancelled",
                  label: "Cancelled",
                },
                {
                  value: "Refunded",
                  label: "Refunded",
                },
              ]}
            />
          </td>
        ) : (
          <td>{data.cancelStatus ? data.cancelStatus : "null"}</td>
        )}
      </tr>
    </>
  );
}
