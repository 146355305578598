import React from "react";
import style from "./PageNotFound.module.css";

export default function PageNotFound() {
  return (
    <div className={style.notFoundMessage}>
      {/* <h1>--- 404 ---</h1> */}
      <h1>
        <span>--- 404 ---</span>
        <br />
        PAGE NOT FOUND
      </h1>
    </div>
  );
}
