import React from "react";
import VehicleList from "../VehicleList/VehicleList";
import style from "./OurVehicle.module.css";

export default function OurVehicle() {
	return (
		<>
			<div className={style.ListWrapper}>
				<div className={style.Content}>
					<h1 className={style.h1Tag}>Our Vehicles</h1>
					<VehicleList filter="homepage" />
				</div>
			</div>
		</>
	);
}
