import React, { useEffect, useRef, useState } from "react";
import style from "./Navbar.module.css";
import { Links } from "../../Data/Navlinks";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/actual_logo.jpg";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import "./Navbar.module.css";

const Navbar = () => {
  const [Navtogled, setNavtogled] = useState(false);
  const [userData, setUserData] = useState("");

  let token = localStorage.getItem("access_token_cb");
  const navigate = useNavigate();

  // this is passport JS login
  let authUser = JSON.parse(localStorage.getItem("authKey"));

  const handleLogout = () => {
    if (token) {
      localStorage.removeItem("access_token_cb");
      toast.success("You’ve logged out.");
      navigate("/login");
    } else {
      console.log("token doesnot exist");
    }
  };

  // passport Js logout
  const handleAuthLogout = () => {
    window.open(`${process.env.REACT_APP_API_URL}auth/logout`, "_self");
    localStorage.removeItem("authKey");
    toast.success("You’ve logged out.");
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    }
    let handler = (event) => {
      if (!navRef.current.contains(event.target)) {
        setNavtogled(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
    // eslint-disable-next-line
  }, [token]);

  let navRef = useRef();
  return (
    <div>
      <header className={style.header} ref={navRef}>
        <div className={style.Navigation_Container}>
          <div className={style.Links}>
            <a href="/" className={style.logo} aria-label="Go to home page">
              <div className={style.headerrlogo}>
                <img src={Logo} alt="logo" />
              </div>
            </a>
            <input
              type="checkbox"
              id={style.navCheck}
              onClick={() => setNavtogled((Navtogled) => !Navtogled)}
              checked={Navtogled}
              readOnly
              aria-label="Togle navbar menu visibility"
            ></input>
            <label htmlFor={style.navCheck} className={style.navToggler}>
              <span></span>
            </label>
            <nav className={style.nav}>
              <ul>
                {Links.map((Link) => (
                  <li key={Link.Key}>
                    <NavLink
                      onClick={() => setNavtogled((Navtogled) => !Navtogled)}
                      to={Link.Linkto}
                      className={({ isActive }) => {
                        return (
                          "Link_Text_" + (isActive ? "Active" : "Unactive")
                        );
                        //!------------------------------ css properties are in App.css ---------------------------------------------------
                      }}
                    >
                      {Link.LinkName}
                    </NavLink>
                  </li>
                ))}
                {token || authUser ? (
                  // <li
                  //   className={
                  //     style.logoutBTN + " Link_Text_Unactive logout_btn"
                  //   }
                  //   onClick={handleLogout}
                  // >
                  //   Logout
                  // </li>
                  <div className={style.dropdown}>
                    <li className={style.dropbtn + " Link_Text_Unactive"}>
                      Menu
                    </li>
                    <div className={style.dropdownContent}>
                      <Link style={{ cursor: "default" }}>
                        My Account
                        <hr style={{ marginBottom: "10px" }} />{" "}
                        {userData && (
                          <>
                            {userData?.fullName} <br />
                            {userData?.email}
                          </>
                        )}
                        {authUser && (
                          <>
                            {authUser?.displayName} <br />
                            {authUser?.emails[0].value}
                          </>
                        )}
                      </Link>
                      {userData &&
                        (userData.role === "admin" ||
                          userData.role === "superAdmin") && (
                          <Link to="/admin/dash">Admin Panel</Link>
                        )}
                      <Link to="/myBookings">My Reservation</Link>
                      {!authUser && (
                        <Link to="/changepassword">Change Password</Link>
                      )}
                      {authUser ? (
                        <Link
                          to="/"
                          className={style.logoutBTN}
                          onClick={handleAuthLogout}
                        >
                          Logout
                        </Link>
                      ) : (
                        <Link
                          to="/"
                          className={style.logoutBTN}
                          onClick={handleLogout}
                        >
                          Logout
                        </Link>
                      )}
                    </div>
                  </div>
                ) : (
                  <li>
                    <Link to="/login" className="Link_Text_Unactive">
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
