import React, { useContext, useEffect, useState } from "react";
import "../ChartContainer.css";
import PieChartComponent from "../../../Components/Charts/PieChartComponent";
import CarReportContext from "../../../Context/CarReport/CarReportContext";

export default function PieChartContainer() {
  const { crloading, carReportData, createAllCarReport } =
    useContext(CarReportContext);
  const [displayOptionName, setDisplayOptionName] = useState("Top");
  const [displayOption, setDisplayOption] = useState("1");
  const [displayData, setDisplayData] = useState([]);

  //stupid MUI api . just to make the ui make beautiful this width on pieChart Label name
  const [displayWidth, setDisplayWidth] = useState(506);

  let data = [];
  const dataExtracter = () => {
    console.log("carReportData : ", carReportData);
    data = [];
    if (carReportData) {
      if (displayOption === "1") {
        carReportData.slice(0, 10).map((carReport, i) => {
          data.push({
            id: i,
            value: parseInt(carReport.bookingRequest),
            label: carReport.carDetail
              ? carReport.carDetail.name
              : "*Car Deleted*",
          });
        });
        setDisplayOptionName("Top");
        setDisplayData(data);
        setDisplayWidth(506);
      } else {
        carReportData.slice(-10).map((carReport, i) => {
          data.push({
            id: i,
            value: parseInt(carReport.bookingRequest),
            label: carReport.carDetail
              ? carReport.carDetail.name
              : "*Car Deleted*",
          });
        });
        setDisplayOptionName("Least");
        setDisplayData(data);
        setDisplayWidth(560);
      }
    }
  };

  useEffect(() => {
    dataExtracter();
  }, [displayOption, crloading]);

  return (
    <div className="DashBoardChart">
      <div className="DashChart_TitleSelector">
        <div className="DashChart_Title">
          <h4 onClick={() => createAllCarReport()}>
            {displayOptionName} Trending Cars
          </h4>
        </div>
        <div className="DashChart_Selector">
          <select
            className="form-select"
            aria-label="Default select example"
            value={displayOption}
            onChange={(e) => {
              setDisplayOption(e.target.value);
            }}
          >
            <option value="1">Top Trending</option>
            <option value="0">Least Trending</option>
          </select>
        </div>
      </div>
      <PieChartComponent data={displayData} width={displayWidth} />
    </div>
  );
}
