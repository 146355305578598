import React from "react";
import style from "./InfoBar.module.css";
import service_onTime from "../../assets/services/ontime.png";
import service_cars from "../../assets/services/brandnewcars.png";
import service_hasslefree from "../../assets/services/hasslefree.png";
import service_247 from "../../assets/services/247service.png";

const InfoBar = () => {
	return (
		<>
			<div className={style.InfoWrapper}>
				<div className={style.InfoBar}>
					<img src={service_onTime} alt="" />
					<img src={service_cars} alt="" />
					<img src={service_hasslefree} alt="" />
					<img src={service_247} alt="" />
				</div>
			</div>
		</>
	);
};

export default InfoBar;
