import { LineChart } from "@mui/x-charts";
import React from "react";

export default function LineChartComponent(props) {
  const { years, cashFlow } = props;
  // const years = [
  //   new Date(2023, 5 - 1),
  //   new Date(2023, 6 - 1),
  //   new Date(2023, 7 - 1),
  //   new Date(2023, 8 - 1),
  //   new Date(2023, 9 - 1),
  //   new Date(2023, 10 - 1),
  // ];

  // const years = [
  //   new Date(2023, 6 - 1, 1 - 1),
  //   new Date(2023, 6 - 1, 2 - 1),
  //   new Date(2023, 6 - 1, 3 - 1),
  //   new Date(2023, 6 - 1, 4 - 1),
  //   new Date(2023, 6 - 1, 5 - 1),
  //   new Date(2023, 6 - 1, 6 - 1),
  //   new Date(2023, 6 - 1, 7 - 1),
  // ];

  // const cashFlow = [100, 200, 50, 600, 500, 600, 0];
  // const cashFlow = [0, 0, 0, 50, 0, 0, 0];
  return (
    <LineChart
      xAxis={[
        {
          id: "Years",
          data: years,
          scaleType: "time",
        },
      ]}
      series={[
        {
          id: "cashFlow",
          label: "Cash Flow",
          data: cashFlow,
          stack: "total",
          area: false,
          showMark: false,
        },
      ]}
      width={500}
      height={280}
    />
  );
}
