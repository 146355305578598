import React, { useEffect, useState } from "react";
import style from "./ViewAdmin.module.css";
// import { MdDeleteSweep } from "react-icons/md";
//MdOutlineModeEditOutline
import { Link } from "react-router-dom";
import { getAllData } from "../../../constants/apiService";

// import moment from "moment";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
// import { Button, Space, Tooltip, Popconfirm, Select } from "antd";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { amber } from "@mui/material/colors";
import AdminAccountList from "../../../Components/AdminAccountTable/AdminAccountList";
import PaginationComp from "../../../Components/PaginationComp/PaginationComp";

export default function ViewAdmin() {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState("");
  const [normalUsers, setNormalUsers] = useState([]);
  const [adminUser, setAdminUser] = useState([]);
  const [superUser, setSuperUser] = useState([]);
  const [filter, setFilter] = useState("all");

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [currentAdminPage, setCurrentAdminPage] = useState(1);
  const [currentSuperPage, setCurrentSuperPage] = useState(1);
  const [postsPerPage] = useState(10);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const url = "/user/getallusers";
      const result = await getAllData(url);
      if (result.status === 200) {
        setLoading(false);
        let Notices = result.data;
        setDatas(Notices);
        setNormalUsers([]);
        setAdminUser([]);
        setSuperUser([]);
        if (Notices) {
          // eslint-disable-next-line array-callback-return
          Notices.map((data) => {
            if (data.role === "superAdmin") {
              setSuperUser((superUser) => [...superUser, data]);
            } else if (data.role === "admin") {
              setAdminUser((adminUser) => [...adminUser, data]);
            } else {
              setNormalUsers((normalUsers) => [...normalUsers, data]);
            }
          });
        }
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.message);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  // const handleDelete = async (id) => {
  //   if (id) {
  //     const url = `/user/${id}`;
  //     const result = await deleteById(url);
  //     if (result.status === 200) {
  //       fetchAll();
  //       toast.success("User removed");
  //     } else if (result.response.status === 404) {
  //       toast.error(result.response.statusText);
  //     } else if (result.response.status === 400) {
  //       toast.error(result.response.data.errors[0].message);
  //     } else {
  //       toast.error(result.response.data.reason);
  //     }
  //   }
  // };

  // const handleRoleChange = async (value, recordItem) => {
  //   let userRole = {
  //     role: value,
  //   };
  //   const url = `/user/updateRole/${recordItem}`;
  //   const result = await updateData(url, userRole);
  //   if (result.status === 200) {
  //     toast.success(
  //       `${recordItem.fullName}  role changed to ${value} sucessfully`
  //     );
  //   } else if (result.response.status === 403) {
  //     toast.error(`You are not permitted to change the role`);
  //   } else {
  //     toast.error(`Error`);
  //   }
  // };

  // for pagination : Get Current datas
  const indexOfLastData = currentPage * postsPerPage;
  const indexOfFirstData = indexOfLastData - postsPerPage;
  const currentData = datas.slice(indexOfFirstData, indexOfLastData);

  const indexOfLastUserData = currentUserPage * postsPerPage;
  const indexOfFirstUserData = indexOfLastUserData - postsPerPage;
  const currentUserData = normalUsers.slice(
    indexOfFirstUserData,
    indexOfLastUserData
  );

  const indexOfLastAdminData = currentAdminPage * postsPerPage;
  const indexOfFirstAdminData = indexOfLastAdminData - postsPerPage;
  const currentAdminData = adminUser.slice(
    indexOfFirstAdminData,
    indexOfLastAdminData
  );

  const indexOfLastSuperData = currentSuperPage * postsPerPage;
  const indexOfFirstSuperData = indexOfLastSuperData - postsPerPage;
  const currentSuperData = superUser.slice(
    indexOfFirstSuperData,
    indexOfLastSuperData
  );

  // for pagination : Change page
  const paginate = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === ">") {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const paginateUser = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentUserPage(currentUserPage - 1);
    } else if (pageNumber === ">") {
      setCurrentUserPage(currentUserPage + 1);
    } else {
      setCurrentUserPage(pageNumber);
    }
  };

  const paginateAdmin = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentAdminPage(currentAdminPage - 1);
    } else if (pageNumber === ">") {
      setCurrentPage(currentAdminPage + 1);
    } else {
      setCurrentAdminPage(pageNumber);
    }
  };

  const paginateSuper = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentSuperPage(currentSuperPage - 1);
    } else if (pageNumber === ">") {
      setCurrentSuperPage(currentSuperPage + 1);
    } else {
      setCurrentSuperPage(pageNumber);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.loadingTextDiv}>
          <h1 className={style.loadingText}>... Loading ...</h1>
        </div>
      ) : (
        <div className={style.ViewAdminTableWrapper}>
          <div className={style.ViewAdminTable}>
            <div className={style.FormHeading}>
              <div className={style.FH_left}>
                {/* <Link to="/admin/view/add" className={style.baseBTN}>
									Add User
								</Link> */}
              </div>
              <div className={style.FH_right}>
                <h1>Admin List</h1>
              </div>
            </div>

            <FormControl>
              {/* <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  color: amber[600],
                  "&.Mui-focused": {
                    color: amber[400],
                  },
                }}
              >
                Filter
              </FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="all"
              >
                <FormControlLabel
                  value="all"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("all");
                      }}
                    />
                  }
                  label="All"
                />
                <FormControlLabel
                  value="superAdmin"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("superAdmin");
                      }}
                    />
                  }
                  label="Super Admin"
                />

                <FormControlLabel
                  value="admin"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("admin");
                      }}
                    />
                  }
                  label="Admin"
                />

                <FormControlLabel
                  value="user"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("user");
                      }}
                    />
                  }
                  label="User"
                />
              </RadioGroup>
            </FormControl>

            {filter === "all" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={datas.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}

            {filter === "superAdmin" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={superUser.length}
                paginate={paginateSuper}
                currentPage={currentSuperPage}
              />
            )}

            {filter === "admin" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={adminUser.length}
                paginate={paginateAdmin}
                currentPage={currentAdminPage}
              />
            )}

            {filter === "user" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={normalUsers.length}
                paginate={paginateUser}
                currentPage={currentUserPage}
              />
            )}

            <table className={style.VCTable}>
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>SN</th>
                  <th style={{ width: "20%" }}>Full Name</th>
                  <th style={{ width: "20%" }}>Email</th>
                  <th style={{ width: "15%" }}>Role</th>
                  <th style={{ width: "20%" }}>Created On</th>
                  <th style={{ width: "20%" }}>Actions</th>
                </tr>
                {/* {Array.isArray(datas) &&
                  datas.map((data, i) => {
                    return (
                      <>
                        <AdminAccountTable data={data} i={i} show={filter} />
                      </>
                    );
                  })} */}

                {filter === "all" && (
                  <>
                    {Array.isArray(currentData) &&
                      currentData.map((data, i) => {
                        return (
                          <>
                            <AdminAccountList
                              key={i}
                              data={data}
                              i={indexOfFirstData + i}
                              fetchAgain={fetchAll}
                            />
                          </>
                        );
                      })}
                  </>
                )}

                {filter === "superAdmin" && (
                  <>
                    {Array.isArray(currentSuperData) &&
                      currentSuperData.map((data, i) => {
                        return (
                          <>
                            <AdminAccountList
                              key={i}
                              data={data}
                              i={indexOfFirstSuperData + i}
                              fetchAgain={fetchAll}
                            />
                          </>
                        );
                      })}
                  </>
                )}

                {filter === "admin" && (
                  <>
                    {Array.isArray(currentAdminData) &&
                      currentAdminData.map((data, i) => {
                        return (
                          <>
                            <AdminAccountList
                              key={i}
                              data={data}
                              i={indexOfFirstAdminData + i}
                              fetchAgain={fetchAll}
                            />
                          </>
                        );
                      })}
                  </>
                )}

                {filter === "user" && (
                  <>
                    {Array.isArray(currentUserData) &&
                      currentUserData.map((data, i) => {
                        return (
                          <>
                            <AdminAccountList
                              key={i}
                              data={data}
                              i={indexOfFirstUserData + i}
                              fetchAgain={fetchAll}
                            />
                          </>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>

            {filter === "all" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={datas.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}

            {filter === "superAdmin" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={superUser.length}
                paginate={paginateSuper}
                currentPage={currentSuperPage}
              />
            )}

            {filter === "admin" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={adminUser.length}
                paginate={paginateAdmin}
                currentPage={currentAdminPage}
              />
            )}

            {filter === "user" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={normalUsers.length}
                paginate={paginateUser}
                currentPage={currentUserPage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
