import React, { useContext, useState } from "react";
import {
  getAllFormData,
  storeData,
  storeFormData,
} from "../../constants/apiService";
import { toast } from "react-toastify";
import CarReportContext from "./CarReportContext";
import BookingContext from "../Booking/BookingContext";

const CarReportState = (props) => {
  const [crloading, setLoading] = useState(false);
  const [carReportData, setCarReportData] = useState();
  const [carDatas, setCarDatas] = useState();

  const { bookingData } = useContext(BookingContext);

  const fetchCarReportData = async () => {
    setLoading(true);
    try {
      const url = "/carreport";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let carsReport = result.data;
        setCarReportData(
          carsReport.data.sort((a, b) => b.bookingRequest - a.bookingRequest)
        );
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const fetchAllCars = async () => {
    try {
      setLoading(true);
      const url = "/cars/getallcars";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let cars = result.data;
        setCarDatas(cars);
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const storeCarData = async (id, bookingRequest) => {
    try {
      const url = "/carreport";
      const data = { carDetail: id, bookingRequest: bookingRequest };
      const result = await storeData(url, data);
      if (result.status === 200) {
        setLoading(false);
        // toast.success("car created sucessfully");
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (err) {
      console.log("creating car Report error ", err);
    }
  };

  const createAllCarReport = async () => {
    setLoading(true);
    fetchAllCars();

    if (carDatas) {
      carDatas.map((carData, i) => {
        let result = bookingData.filter(
          (booked) => booked.car && booked.car._id == carData._id
        );
        storeCarData(carData._id, result.length);
      });
    }
  };

  return (
    <CarReportContext.Provider
      value={{
        fetchCarReportData,
        crloading,
        carReportData,
        createAllCarReport,
      }}
    >
      {props.children}
    </CarReportContext.Provider>
  );
};

export default CarReportState;
