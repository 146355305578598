import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../constants/axiosInstance";
import { toast } from "react-toastify";
import style from "./PrivacyPolicy.module.css";
import uuid from "react-uuid";

const PrivacyPolicy = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const dummyArr = [0, 1, 2, 3, 4, 5];

  let fetchPolicy = async () => {
    let response = await axiosInstance.get("/policy/getallpolicy");
    if (response.status === 200) {
      setData(response.data.data[0]);
      setLoading(false);
    } else toast.error("Couldn't fetch Privacy Policy");
  };

  useEffect(() => {
    fetchPolicy();
  }, []);
  return (
    <>
      {loading && (
        <div className={style.Wrapper}>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                  <div className={style.H1Div}></div>
                  <div className={style.H2Div}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {data && (
        <div className={style.Wrapper}>
          <div className={style.Heading}>
            <h1>{data.title}</h1>
          </div>
          <div className={style.Box}>
            <div className={style.MainInfo}>
              <p className={style.descriptionP}>{data.description}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
