import React, { useState } from "react";
import { MdDeleteSweep } from "react-icons/md";
import moment from "moment";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import { Button, Space, Tooltip, Popconfirm, Select } from "antd";
import { deleteById, getAllData, updateData } from "../../constants/apiService";

export default function AdminAccountList(props) {
  const { data, i, fetchAgain } = props;

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [datas, setDatas] = useState("");

  const fetchAll = async () => {
    try {
      setLoading(true);
      const url = "/user/getallusers";
      const result = await getAllData(url);
      if (result.status === 200) {
        setLoading(false);
        let Notices = result.data;
        setDatas(Notices);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.message);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const handleDelete = async (id) => {
    if (id) {
      const url = `/user/${id}`;
      const result = await deleteById(url);
      if (result.status === 200) {
        fetchAll();
        toast.success("User removed");
      } else if (result.response.status === 404) {
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        toast.error(result.response.data.errors[0].message);
      } else {
        toast.error(result.response.data.reason);
      }
    }
  };

  const handleRoleChange = async (value, recordItem) => {
    let userRole = {
      role: value,
    };
    const url = `/user/updateRole/${recordItem}`;
    const result = await updateData(url, userRole);
    if (result.status === 200) {
      fetchAgain();
      toast.success(`role changed to ${value} sucessfully`);
    } else if (result.response.status === 403) {
      toast.error(`You are not permitted to change the role`);
    } else {
      toast.error(`Error`);
    }
  };

  return (
    <>
      <tr key={data._id}>
        <td>{i + 1}</td>
        <td>{data.fullName}</td>
        <td>{data.email}</td>
        <td>
          <Select
            defaultValue={data.role}
            style={{
              width: "80%",
            }}
            onChange={(value) => handleRoleChange(value, data._id)}
            options={[
              {
                value: "superAdmin",
                label: "superAdmin",
              },
              {
                value: "admin",
                label: "admin",
              },
              {
                value: "user",
                label: "user",
              },
            ]}
          />
        </td>
        <td>{moment(data.updatedAt).format("MMMM Do YYYY")}</td>
        <td>
          <Space size="middle">
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                title="Delete record?"
                onConfirm={() => handleDelete(data.id)}
              >
                <Button
                  className="mr-1"
                  style={{
                    border: 0,
                  }}
                >
                  <MdDeleteSweep style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        </td>
      </tr>
    </>
  );
}
