import React, { useEffect, useState } from "react";
import style from "./Popular.module.css";
import { getAllFormData } from "../../constants/apiService";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const Popular = () => {
  //states
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const dummyArr = [0, 1, 2];

  const fetchAll = async () => {
    const url = "category/getallcategory";

    const result = await getAllFormData(url);
    if (result.status === 200) {
      let categories = result.data;
      setData(categories);
      setLoading(false);
    } else if (result.status === 404) {
      toast.error(result.statusText);
    } else if (result.status === 400) {
      // toast.error(result.data.errors[0].message);
    } else {
      // toast.error("error fetching data");
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <>
      {loading && (
        <div className={style.Popular}>
          <h1>Vehicle Category</h1>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                  {/* <div className={style.H1Div}></div> */}
                  {/* <div className={style.H2Div}></div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {data && !loading && (
        <div className={style.PopularWrapper}>
          <div className={style.Popular}>
            <h1>Vehicle Category</h1>
            <div className={style.CarList}>
              {data?.map((Car) => (
                <div className={style.IndividualCar} key={Car._id}>
                  <img
                    src={`${Car.imageUrl}`}
                    alt="Car"
                  />
                  <div className={style.CarInfo}>
                    <h2>{Car.title}</h2>
                    <p>{Car.description}</p>
                    <Link to={`/category/${Car.title}`}>
                      <button>Check {Car.title} fleet</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popular;
