import {
  MdDashboard,
  MdPermContactCalendar,
  MdShoppingCart,
  MdImage,
  MdMedicalServices
} from "react-icons/md";

import { SiCashapp } from "react-icons/si";
import { AiFillWarning } from "react-icons/ai";

export const SettingsNavLinks = [
  {
    Key: 1,
    Title: "About Us",
    Linkto: "/admin/settings/about",
    Logo: <MdDashboard />,
  },
  {
    Key: 2,
    Title: "Contact Info",
    Linkto: "/admin/settings/contact",
    Logo: <MdPermContactCalendar />,
  },
  {
    Key: 3,
    Title: "Cover Image",
    Linkto: "/admin/settings/coverImage",
    Logo: <MdImage />,
  },
  {
    Key: 0,
    Title: "Theme Change",
    Linkto: "/admin/settings/theme",
    Logo: <MdShoppingCart />,
  },
  {
    Key: 4,
    Title: "Stripe Setting",
    Linkto: "/admin/settings/Stripe",
    Logo: <SiCashapp />,
  },
  {
    Key: 5,
    Title: "Privacy Policy",
    Linkto: "/admin/settings/privacy-policy",
    Logo: <AiFillWarning />,
  },
  {
    Key: 6,
    Title: "Services",
    Linkto: "/admin/settings/services",
    Logo: <MdMedicalServices />,
  },
];
