import { MdAdminPanelSettings, MdSettings } from "react-icons/md";

export const SuperAdminNavLinks = [
  {
    Key: 1,
    Title: "Admin Details",
    Linkto: "/admin/view",
    Logo: <MdAdminPanelSettings />,
  },
  {
    Key: 2,
    Title: "Configure",
    Linkto: "/admin/settings/about",
    Logo: <MdSettings />,
    SideBar: true,
  },
];
