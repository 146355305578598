import React, { useState } from "react";
import style from "./AddAdmin.module.css";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { storeData } from "../../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const AddAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = `/user/sign-up`;
    const result = await storeData(url, enterdData);
    if (result.status === 201) {
      setLoading(false);
      navigate("/admin/view");
    } else if (result.response.status === 404) {
      setLoading(false);
      toast.error(result.response.statusText);
    } else if (result.response.status === 400) {
      setLoading(false);
      toast.error(result.response.data.errors[0].message);
    } else {
      setLoading(false);
      toast.error(result.response.data.reason);
    }
  };
  return (
    <>
      <div className={style.AddAdminWrapper}>
        <div className={style.AddAdmin}>
          <div className={style.FormHeading}>
            <div className={style.FH_left}>
              <Link to="/admin/view" className={style.baseBTN_nobg}>
                <MdKeyboardBackspace />
              </Link>
            </div>
            <div className={style.FH_right}>
              <h1>Add Admin</h1>
            </div>
          </div>
          <form
            autoComplete="off"
            className={style.AdminForm}
            onSubmit={handleFormSubmit}
          >
            <h1>Full Name</h1>
            <input
              name="name"
              type="text"
              required
              aria-label="Full Name"
            ></input>

            <h1>Email Id</h1>
            <input
              name="email"
              type="email"
              required
              aria-label="Email"
            ></input>

            <h1>Password</h1>
            <input
              name="password"
              type="password"
              required
              aria-label="Password"
            ></input>

            {/* <h1>Access type</h1>
            <select name="AccessType">
              <option value="null">Select category</option>
              <option value="SuperAdmin">Super Admin</option>
              <option value="Admin">Admin</option>
            </select> */}

            {loading ? (
              <button type="Submit" color="primary" outline disabled>
                ... Processing ...
              </button>
            ) : (
              <button type="Submit" color="primary" outline disabled={loading}>
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
