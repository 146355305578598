export const Links = [
	{
		Key: 1,
		LinkName: "Home",
		Linkto: "/",
	},
	// {
	//   Key: 2,
	//   LinkName: "Services",
	//   Linkto: "/services",
	// },
	// {
	//   Key: 3,
	//   LinkName: "Rates",
	//   Linkto: "/rates_details",
	// },
	{
		Key: 4,
		LinkName: "Reservation",
		Linkto: "/reservation",
	},
	{
		Key: 5,
		LinkName: "Contact",
		Linkto: "/contact",
	},
	{
		Key: 6,
		LinkName: "About",
		Linkto: "/about",
	},
];
