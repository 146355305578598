import React, { useEffect, useState } from "react";
import style from "./TextBanner.module.css";
import { getAllData } from "../../constants/apiService";
import HTMLReactParser from "html-react-parser";
import { toast } from "react-toastify";

const TextBanner = () => {
  const [aboutData, setAboutData] = useState("");

  const fetchAbout = async () => {
    try {
      const url = "/about/getallabout";
      const result = await getAllData(url);
      if (result.status === 200) {
        const resData = result.data[0].description;
        setAboutData(resData);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };

  useEffect(() => {
    fetchAbout();
  }, []);
  return (
    <>
      <div className={style.TextWrapper}>
        <div className={style.Text}>
          <div className={style.Heading}>
            <h1>Limo Service & Black Car service</h1>
          </div>
          <hr className={style.textBannerHR}></hr>
          {/* <p>{HTMLReactParser(aboutData)}</p> */}
        </div>
      </div>
    </>
  );
};

export default TextBanner;
