import React from "react";
import style from "./PaginationComp.module.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

export default function PaginationComp({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) {
  const pageNumbers = [];
  const totalPageCount = Math.ceil(totalPosts / postsPerPage);
  const showingNum = 8;
  let rangeNum = Math.floor(showingNum / 2);

  // for (let i = 1; i <= totalPageCount; i++) {
  //   pageNumbers.push(i);
  // }

  if (totalPageCount === 1) {
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push();
    }
  } else if (currentPage === 1) {
    if (totalPageCount > showingNum) {
      for (let i = 1; i <= showingNum; i++) {
        pageNumbers.push(i);
      }
      // pageNumbers.push("...");
    } else {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    }
  } else if (currentPage === totalPageCount) {
    if (totalPageCount > showingNum) {
      for (let i = totalPageCount; i > totalPageCount - showingNum; i--) {
        pageNumbers.unshift(i);
      }
    } else {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    }
  } else if (currentPage < rangeNum + 1) {
    if (totalPageCount > showingNum) {
      for (let i = 1; i <= showingNum; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    }
  } else if (currentPage > totalPageCount - rangeNum - 1) {
    if (totalPageCount > showingNum) {
      for (let i = totalPageCount; i > totalPageCount - showingNum; i--) {
        pageNumbers.unshift(i);
      }
    } else {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    }
  } else {
    if (totalPageCount > showingNum) {
      for (let i = currentPage - rangeNum; i < currentPage + rangeNum; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <nav>
      <ul className={style.pagination_UL}>
        {totalPageCount !== 1 && (
          <>
            {currentPage !== pageNumbers[0] ? (
              <div
                className={style.page_nav_prev}
                onClick={() => paginate("<")}
              >
                <MdNavigateBefore />
              </div>
            ) : (
              <div className={style.page_nav_disabled} disabled>
                <MdNavigateBefore />
              </div>
            )}
          </>
        )}

        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <div onClick={() => paginate(number)} className="page-link">
              {currentPage === number ? (
                <span className={style.selectedPage}>{number}</span>
              ) : (
                number
              )}
            </div>
          </li>
        ))}

        {totalPageCount !== 1 && (
          <>
            {currentPage !== totalPageCount ? (
              <div
                className={style.page_nav_next}
                onClick={() => paginate(">")}
              >
                <MdNavigateNext />
              </div>
            ) : (
              <div className={style.page_nav_disabled} disabled>
                <MdNavigateNext />
              </div>
            )}
          </>
        )}
      </ul>
    </nav>
  );
}
