import React, { useEffect, useState } from "react";
import style from "./Login.module.css";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const Login = () => {
  const emailRef = useRef();

  const [loading, setLoading] = useState(false);
  const [showNow, setShowNow] = useState("block");

  const navigate = useNavigate();

  const isAlreadyLoggedIn = localStorage.getItem("access_token_cb");

  // const google = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}auth/google`, "_self");
  // };

  // const facebook = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}auth/facebook`, "_self");
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = "/user/login";

    const loginData = {
      email: enterdData.Email,
      password: enterdData.Password,
    };
    const response = await storeData(url, loginData);

    if (response.data) {
      const { token } = response.data;
      localStorage.setItem("access_token_cb", token); //setting access token in localStorage
      navigate("/");
      setLoading(false);
      window.location.reload();
      toast.success("Logged In Sucessfully");
    } else {
      setLoading(false);
      toast.error(
        "Please make sure you entered the correct information and try again."
      );
    }
  };

  // function to show and hide password
  // attributes are InputID, showSVGIconID, hideSVGIconID
  const onPasswordShow = (inputId, showSVG, hideSVG) => {
    const passwordForm = document.getElementById(inputId);
    const showPassSVG = document.getElementById(showSVG);
    const hidePassSVG = document.getElementById(hideSVG);
    const passwordAttr = passwordForm.getAttribute("type");
    if (passwordAttr === "password") {
      passwordForm.setAttribute("type", "text");
      showPassSVG.style.display = "none";
      hidePassSVG.style.display = "block";
    } else {
      passwordForm.setAttribute("type", "password");
      showPassSVG.style.display = "block";
      hidePassSVG.style.display = "none";
    }
  };

  useEffect(() => {
    if (isAlreadyLoggedIn) {
      navigate("/");
    }
    // emailRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.LoginWrapper}>
          <div className={style.WaitingMessage}>
            <h1>... Authenticating ...</h1>
          </div>
        </div>
      ) : (
        <div className={style.LoginWrapper}>
          <h1>Login</h1>
          <form
            onSubmit={handleSubmit}
            className={style.FormWrapper}
            autoComplete="off"
          >
            <h2>Email</h2>
            <input type="email" ref={emailRef} required name="Email"></input>
            <div
              className={style.passwordInputDiv}
              style={{ display: showNow }}
            >
              <h2>Password</h2>
              <input
                type="password"
                required
                name="Password"
                id="passwordInput"
              ></input>
              <div className={style.passwordShower}>
                <AiFillEye
                  id="passwordShow_icon"
                  onClick={() => {
                    onPasswordShow(
                      "passwordInput",
                      "passwordShow_icon",
                      "passwordHide_icon"
                    );
                  }}
                  style={{ display: "block" }}
                />
                <AiFillEyeInvisible
                  id="passwordHide_icon"
                  onClick={() => {
                    onPasswordShow(
                      "passwordInput",
                      "passwordShow_icon",
                      "passwordHide_icon"
                    );
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            {showNow === "none" ? (
              <div
                className={style.showDivBTN}
                onClick={() => {
                  setShowNow("block");
                }}
              >
                Continue
              </div>
            ) : (
              <button>Login</button>
            )}
            <Link to={"/reset"} className="resetPassword">
              <span>Forgot Password</span>
            </Link>
            <hr style={{ margin: "10px 0px" }} />
            {/* <SocialButton
              logo={<FcGoogle />}
              title="Sign in with Google"
              linkTo={google}
            />
            <SocialButton
              logo={<MdFacebook />}
              title="Sign in with Facebook"
              linkTo={facebook}
            /> */}
            <Link to={"/register"}>
              <h3>Create Account</h3>
            </Link>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
