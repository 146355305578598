import React from "react";
import "./ContactInfoContainer.css";
import SocialMediaContainer from "./SocialMediaContainer/SocialMediaContainer";
import ContactContainer from "./ContactContainer/ContactContainer";

export default function ContactInfoContainer() {
  return (
    <div className="ContactInfo_wrapper">
      <div className="ContactInfo_container">
        <h1>Contact Information</h1>
        <div className="ContactInfo_contactInfo">
          <div className="dashCI_left">
            <ContactContainer />
          </div>
          <div className="dashCI_right">
            <SocialMediaContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
