import React from "react";
import { PieChart } from "@mui/x-charts";

export default function PieChartComponent(props) {
  const { data, width } = props;

  // const data = [
  //   { id: 0, value: 50, label: "series A" },
  //   { id: 1, value: 15, label: "series B" },
  //   { id: 2, value: 20, label: "series C" },
  //   { id: 3, value: 30, label: "series D" },
  //   { id: 4, value: 60, label: "series E" },
  // ]

  return (
    <PieChart
      series={[
        {
          data: data,
          innerRadius: 30,
          outerRadius: 100,
          paddingAngle: 3,
          cornerRadius: 5,
          startAngle: 0,
          endAngle: 360,
          cx: 120,
          cy: 120,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -2 },
        },
      ]}
      width={width}
      height={350}
      sx={{
        cursor: "pointer",
      }}
    />
  );
}
