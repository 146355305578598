import React, { useState } from "react";
import { getAllData, getAllFormData } from "../../constants/apiService";
import { toast } from "react-toastify";
import NotificationContext from "./NotificationContext";

const NotificationState = (props) => {
  const [nloading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState();

  const fetchNotificationData = async () => {
    console.log("I am in notification");
    setLoading(true);
    try {
      const url = "/notification";
      const result = await getAllData(url);
      if (result.status === 200) {
        let notification = result.data;
        console.log("notification : ", notification);
        setNotificationData(notification.reverse());
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        fetchNotificationData,
        notificationData,
        nloading,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
