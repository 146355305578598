import React, { useEffect, useState } from "react";
import Disclaimer from "../../Components/Disclaimer/Disclaimer";
import Login from "../../Components/Login/Login";
import style from "./BookingPage.module.css";
import { BiDollar } from "react-icons/bi";
import { MdLuggage } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";

import "react-datepicker/dist/react-datepicker.css";
import ContinueAsGuest from "../../Components/ContinueAsGuest/ContinueAsGuest";
import {
  // useNavigate,
  useParams,
} from "react-router-dom";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import { storeData, getDataById, getAllData } from "../../constants/apiService";
import jwt_decode from "jwt-decode";
import moment from "moment";
import StepAndDash from "../../Components/StepAndDash/StepAndDash";
import uuid from "react-uuid";
import axios from "axios";
import UnavailableTime from "../../Components/UnavailableTime/UnavailableTime";
import ReactHTMLParser from "html-react-parser";

const BookingPage = () => {
  const dummyArr = [0]; // just to show loading skeleton

  const [userData, setUserData] = useState("");
  let [PassengerNumber, setPassengerNumber] = useState(1);
  const [LuggageWeight, setLuggageWeight] = useState(1);
  const [disablePassengerDecrease, setDisablePassengerDecrease] =
    useState(false);
  const [disablePassengerIncrease, setDisablePassengerIncrease] =
    useState(false);
  const [disableLuggageIncrease, setDisableLuggageIncrease] = useState(false);
  const [disableLuggageDecrease, setDisableLuggageDecrease] = useState(false);
  const [loadingPickUPDate, setLoadingPickUPDate] = useState(true);
  const [bookedDatesEmpty, setBookedDatesEmpty] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState("Hidden");
  const [loadingDates, setLoadingDates] = useState([]);
  const [bookingData, setBookingData] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loggedStatus, setLoggedStatus] = useState(false);
  const [carData, setCarData] = useState([]);
  const [showSmallPickup, setshowSmallPickup] = useState(false);
  const [showSmallDropOff, setshowSmallDropOff] = useState(false);
  const [services,setServices] = useState([])
  // const [dateInputField, setDateInputField] = useState("");
  let { carid } = useParams();

  let token = localStorage.getItem("access_token_cb");
  let authUser = JSON.parse(localStorage.getItem("authKey"));

  // ------------------------------------------------------------------  increase and decrease functions for  passenger no and luggage wt ------------------------------------------------------------
  const increasePassenger = () => {
    setPassengerNumber(PassengerNumber + 1);
  };
  const DecreasePassenger = () => {
    setPassengerNumber(PassengerNumber - 1);
  };

  const increaseLuggage = () => {
    setLuggageWeight(LuggageWeight + 1);
  };
  const decreaseLuggage = () => {
    setLuggageWeight(LuggageWeight - 1);
  };
  const fetchServices=async ()=>{
    try{
        const url="/service/getallservice"
        const response =await getAllData(url)
        if(response.status===200){
          setServices(response?.data?.data)
        }
        else {
            // Handle error scenarios here (e.g., show error message)
            console.error("Failed to fetch data:", response);
        }
    }
    catch(error){
        toast.error("Error fetching services list")
    }
    
}
  //fetching car details
  const fetchSingleCar = async () => {
    const url = `/cars/getsinglecar/${carid}`;
    const result = await getDataById(url);
    if (result.status === 200) {
      setCarData(result.data);
      setInitialLoading(false);
    } else if (result.response.status === 404) {
      toast.error(result.response.statusText);
    } else if (result.response.status === 400) {
      toast.error(result.response.data.errors[0].message);
    } else {
      toast.error(result.response.data.reason);
    }
  };

  const checkAvailableTime = async (e) => {
    if (e.target.name === "pickupDate") {
      setshowSmallPickup(true);
      setshowSmallDropOff(false);
    }
    if (e.target.name === "DropOffDate") {
      setshowSmallDropOff(true);
      setshowSmallPickup(false);
    }

    let dateInp = e.target.value;
    setShowTimePicker("Visible");
    setLoadingPickUPDate(true);
    setSelectedDate(dateInp);
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}bookings/bookingsbydate`,
      {
        date: dateInp,
        carId: carData._id,
      }
    );
    if (response.status === 200) {
      setLoadingPickUPDate(false);
      let dateArr = response.data.bookings;
      if (dateArr.length === 0) {
        setBookedDatesEmpty(true);
      } else {
        setBookedDatesEmpty(false);
      }
      setLoadingDates(dateArr);
    }
  };
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  //function checkout
  const checkout =async (bookedData, carImg) => {
    let dataToSend={
      cost: bookedData.cost.toFixed(2),
      car: bookedData.car.name,
      _id: bookedData._id,
      images: carImg,
      // items: [
      //     { id: 1, quantity: quantity, price: itemPrice, name: itemName }
      // ]
    }
    fetch(`${process.env.REACT_APP_API_URL}payment/initiatepayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(dataToSend),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        // console.log(e.error);
      });
  };

  useEffect(() => {
    fetchServices();
    fetchSingleCar();
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
      setLoggedStatus(true);
    }
    if (authUser) {
      setUserData({
        id: authUser.id,
        fullName: authUser.displayName,
        email: authUser.emails[0].value,
      });
      setLoggedStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
      setLoggedStatus(true);
    }
  }, [token]);

  useEffect(() => {
    // ----------------------------------------------------------  Lower limit for no of passengers  to min value of 1---------------------------------------------------------------------------
    if (PassengerNumber === 1) {
      //for num = 1
      setDisablePassengerDecrease(true);
    }
    if (PassengerNumber > 1) {
      setDisablePassengerDecrease(false);
    }
    // ----------------------------------------------------------  Lower limit for luggage weight to min value of 1---------------------------------------------------------------------------

    if (LuggageWeight === 1) {
      //for num = 1
      setDisableLuggageDecrease(true);
    }

    if (LuggageWeight > 1) {
      setDisableLuggageDecrease(false);
    }

    if (LuggageWeight === carData.luggageCapacity) {
      setDisableLuggageIncrease(true);
    }

    if (LuggageWeight < carData.luggageCapacity) {
      setDisableLuggageIncrease(false);
    }

    // ----------------------------------------------------------  upper limit for no of passengers  to max value equal  to  capacity of car---------------------------------------------------------------------------

    if (PassengerNumber === carData.capacity) {
      setDisablePassengerIncrease(true);
    }
    if (PassengerNumber < carData.capacity) {
      setDisablePassengerIncrease(false);
    }
  }, [
    PassengerNumber,
    LuggageWeight,
    carData.capacity,
    carData.luggageCapacity
  ]);

  const onDataChange = async (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const serviceType=enterdData?.serviceType
    const startTime = enterdData?.pickupTime;
    const endTime = enterdData?.DropOffTime;
    const startDate = moment(enterdData?.pickupDate).format("MM/DD/YYYY");
    const endDate = moment(enterdData?.DropOffDate).format("MM/DD/YYYY");
    const from = enterdData?.pickupLocation;
    const to = enterdData?.DropOffLocation;
    const passengers = PassengerNumber;
    const luggageCount = LuggageWeight;

    try {
      const result = await storeData("/bookings/book", {
        carId: carid,
        userId: userData?.id,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        from: from,
        to: to,
        passengers: passengers,
        luggageCount: luggageCount,
        serviceType:serviceType
      });
      if (result.status === 200) {
        const carImage = carData.imageUrl;
        await checkout(result.data.booking, carImage);
        setLoading(false);
        // toast.success("Car booked sucessfully");
        // navigate("/myBookings");
      } else if (result.status === 509) {
        setLoading(false);
        //car not available at that time
        toast.error(result.message);
      } else if (result.status === 404) {
        setLoading(false);

        toast.error(result.statusText);
      } else if (result.status === 400) {
        setLoading(false);

        toast.error(result.data.errors[0].message);
      } else {
        setLoading(false);

        toast.error(result.response.data.message);
      }
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={style.BookingWrapper}>
        <div className={style.Heading}>
          <h1>Booking Panel</h1>
        </div>

        {initialLoading && (
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        )}

        {!initialLoading && (
          <div className={style.BookingDetails}>
            <div className={style.CarDetails}>
              <img
                src={`${carData.imageUrl}`}
                alt="Car"
              />
              <div className={style.MoreCarDetails}>
                <h1>{carData.name}</h1>
                <div className={style.carDetailsParagraph}>
                  {ReactHTMLParser(carData.description) || "Fill in the text"}
                </div>
                <div className={style.MoreInfo}>
                  <div className={style.Left}>
                    <h2>Price</h2>
                    <div className={style.InfoGraphics}>
                      <BiDollar />
                      <h2>{carData.perHourRent}/hr</h2>
                    </div>
                  </div>
                  <div className={style.Mid}>
                    <h2>Passenger Capcity</h2>
                    <div className={style.InfoGraphics}>
                      <IoIosPerson />
                      <h2>{carData.capacity}</h2>
                    </div>
                  </div>
                  <div className={style.Right}>
                    <h2>Luggage Capacity</h2>
                    <div className={style.InfoGraphics}>
                      <MdLuggage />
                      <h2>{carData.luggageCapacity}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.FormContainer}>
              <form
                onSubmit={onSubmit}
                className={style.DateAndLocationForm}
                autoComplete="off"
              >
                <StepAndDash width="95%" step="1" />

                <div className={style.DateAndLocation}>
                  <div className={style.Left}>
                    <div className={style.selService}>
                      <h2>Select Service</h2>
                      <select 
                        onChange={onDataChange}
                        required
                        name="serviceType"
                      >
                        <option value="Not selected"></option>
                        {
                          services?.map((service)=>
                          <>
                        <option value={service.serviceName}>{service.serviceName}</option>

                          </>)
                        }
                      </select>
                    </div>
                    <div className={style.PickUpDates}>
                      <div className={style.PickUpDateOnly}>                        
                        <h2>Pick Up date</h2>
                        <input
                          type="date"
                          name="pickupDate"
                          aria-label="Pick up date"
                          required
                          onChange={debounce(checkAvailableTime, 1000)}
                          onBlur={onDataChange}
                        />
                      </div>

                      <div className={style.PickUpTimeOnly}>
                        <h2>Pick Up Time</h2>
                        <input
                          type="time"
                          name="pickupTime"
                          aria-label="Pick Up time"
                          onChange={onDataChange}
                          required
                        />
                      </div>
                    </div>
                    {showSmallPickup && (
                      <div className={style.SmallTimePicker}>
                        <div className={showTimePicker}>
                          <div className={style.TimePickerWrapper}>
                            {loadingPickUPDate && (
                              <UnavailableTime
                                content="loading"
                                selDate={selectedDate}
                              />
                            )}
                            {!loadingPickUPDate && bookedDatesEmpty && (
                              <UnavailableTime
                                content="no"
                                selDate={selectedDate}
                              />
                            )}
                            {!bookedDatesEmpty && !loadingPickUPDate && (
                              <UnavailableTime
                                content="yes"
                                dates={loadingDates}
                                selDate={selectedDate}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={style.PickupLocation}>
                      <h2>Pick up location</h2>
                      <input
                        type="text"
                        name="pickupLocation"
                        aria-label="Pick up location"
                        onChange={onDataChange}
                        required
                      />
                    </div>
                    <div className={style.DropOffUpDates}>
                      <div className={style.DropOffDateOnly}>
                        <h2>Drop off date</h2>
                        <input
                          type="date"
                          name="DropOffDate"
                          aria-label="Drop off Date"
                          required
                          onChange={debounce(checkAvailableTime, 1000)}
                          onBlur={onDataChange}
                        />
                      </div>

                      <div className={style.DropOffLoctionOnly}>
                        <h2>Drop off Time</h2>
                        <input
                          type="time"
                          name="DropOffTime"
                          aria-label="Drop off Time"
                          onChange={onDataChange}
                          required
                        />
                      </div>
                    </div>
                    {showSmallDropOff && (
                      <div className={style.SmallTimePicker}>
                        <div className={showTimePicker}>
                          <div className={style.TimePickerWrapper}>
                            {loadingPickUPDate && (
                              <UnavailableTime
                                content="loading"
                                selDate={selectedDate}
                              />
                            )}
                            {!loadingPickUPDate && bookedDatesEmpty && (
                              <UnavailableTime
                                content="no"
                                selDate={selectedDate}
                              />
                            )}
                            {!bookedDatesEmpty && !loadingPickUPDate && (
                              <UnavailableTime
                                content="yes"
                                dates={loadingDates}
                                selDate={selectedDate}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={style.DropOffLocation}>
                      <h2>Drop Off location</h2>
                      <input
                        type="text"
                        name="DropOffLocation"
                        aria-label="Drop off Location"
                        onChange={onDataChange}
                        required
                      />
                    </div>
                    <div className={style.ClickAbles}>
                      <div className={style.PassengerNumberWrapper}>
                        <h2>Passengers</h2>
                        <div className={style.PassengerNo}>
                          <IoIosPerson />
                          <button
                            type="button"
                            onClick={DecreasePassenger}
                            disabled={disablePassengerDecrease}
                          >
                            &lt;
                          </button>
                          <h3>{PassengerNumber}</h3>
                          <button
                            type="button"
                            onClick={increasePassenger}
                            disabled={disablePassengerIncrease}
                          >
                            &gt;
                          </button>
                        </div>
                      </div>
                      <div className={style.LuggageWeightWrapper}>
                        <h2>Luggage Count</h2>
                        <div className={style.Luggage}>
                          <MdLuggage />
                          <button
                            type="button"
                            onClick={decreaseLuggage}
                            disabled={disableLuggageDecrease}
                          >
                            &lt;
                          </button>
                          <h3>{LuggageWeight}</h3>
                          <button
                            type="button"
                            onClick={increaseLuggage}
                            disabled={disableLuggageIncrease}
                          >
                            &gt;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.LargeTimePicker}>
                    <div className={showTimePicker}>
                      <div className={style.TimePickerWrapper}>
                        {loadingPickUPDate && (
                          <UnavailableTime
                            content="loading"
                            selDate={selectedDate}
                          />
                        )}
                        {!loadingPickUPDate && bookedDatesEmpty && (
                          <UnavailableTime
                            content="no"
                            selDate={selectedDate}
                          />
                        )}
                        {!bookedDatesEmpty && !loadingPickUPDate && (
                          <UnavailableTime
                            content="yes"
                            dates={loadingDates}
                            selDate={selectedDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <StepAndDash width="95%" step="2" />

                {loggedStatus ? (
                  <div className={style.FormSubmitContainer}>
                    <h2>{userData?.fullName}</h2>
                    <h2>{userData?.email}</h2>
                    <br />
                    <h1>Are You ready to confirm your Booking?</h1>
                    {loading ? (
                      <button type="submit" disabled>
                        ... Processing ...
                      </button>
                    ) : (
                      <button type="submit">Book Now</button>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </form>

              {!loggedStatus ? (
                <div className={style.LoginDetails}>
                  <>
                    <div className={style.leftLogin}>
                      <Login />
                    </div>
                    <div className={style.rightLogin}>
                      <ContinueAsGuest
                        carid={carid}
                        bookingData={bookingData}
                        luggageData={LuggageWeight}
                        passengerData={PassengerNumber}
                        carData={carData}
                        checkout={checkout}
                      />
                    </div>
                  </>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      <Disclaimer />
    </>
  );
};

export default BookingPage;
