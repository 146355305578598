import React from "react";
import "./CardComponent.css";

export default function CardComponent(props) {
  const { title, value, customClass } = props;
  return (
    <div className={"card mb-3 dashCard " + customClass}>
      {/* <div className="card-header">Total Cars</div> */}
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{value}</p>
      </div>
    </div>
  );
}
