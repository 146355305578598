import React, { useEffect, useState } from "react";
import Hero from "../../Components/Hero/Hero";
import InfoBar from "../../Components/InfoBar/InfoBar";
import TextBanner from "../../Components/TextBanner/TextBanner";
import Popular from "../../Components/Popular Cars/Popular";
import style from "./Homepage.module.css";
import OurVehicle from "../../Components/OurVehicle/OurVehicle";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  let token = localStorage.getItem("access_token_cb");
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const checkPassword = async (passData) => {
    if (passData) {
      setLoading(true);
      await delay(1000);
      navigate("/changepassword");
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
      checkPassword(decodedToken.botPassword);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.LoadingWrapper}>
          <div className={style.WaitingMessage}>
            <h1>... Loading ...</h1>
          </div>
        </div>
      ) : (
        <>
          <Hero />
          <InfoBar />
          <TextBanner />
          <Popular />
          <hr className={style.homepage_HR}></hr>
          <OurVehicle />
        </>
      )}
    </>
  );
};

export default Homepage;
