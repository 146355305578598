import React, { useEffect, useState } from "react";
import style from "./ViewBookings.module.css";
// import { Select } from "antd";
import { MdDelete } from "react-icons/md";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
// import moment from "moment";

import { deleteById, getAllFormData } from "../../../constants/apiService";
// import AdminBookingTable from "../../../Components/AdminBookingTable/AdminBookingTable";
// import { Link } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { amber } from "@mui/material/colors";
import jwt_decode from "jwt-decode";
import PaginationComp from "../../../Components/PaginationComp/PaginationComp";
import AdminBookingList from "../../../Components/AdminBookingTable/AdminBookingList";

const ViewBookings = () => {
  let token = localStorage.getItem("access_token_cb");
  const [userData, setUserData] = useState("");

  const [datas, setDatas] = useState("");
  const [registeredUser, setRegisteredUser] = useState([]);
  const [guestUser, setGuestUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("all");

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRegPage, setCurrentRegPage] = useState(1);
  const [currentGuestPage, setCurrentGuestPage] = useState(1);
  const [postsPerPage] = useState(10);

  const fetchBookingData = async () => {
    setLoading(true);
    try {
      const url = "/bookings/getAll";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let cars = result.data;
        setDatas(cars.data.reverse());
        setGuestUser([]);
        setRegisteredUser([]);
        if (cars) {
          // eslint-disable-next-line array-callback-return
          cars.data.map((data) => {
            if (data.isGuest) {
              setGuestUser((guestUser) => [...guestUser, data]);
            } else {
              setRegisteredUser((registeredUser) => [...registeredUser, data]);
            }
          });
        }
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const deleteBookedDetails = async (bookedID_data, toastDisplay = true) => {
    const url = `/bookings/removebooking/${bookedID_data}`;
    const result = await deleteById(url);
    if (toastDisplay) {
      if (result.status === 200) {
        toast.error("Data deleted");
      } else {
        toast.error("Error");
      }
    } else {
      if (!result.status === 200) {
        toast.error("Error 200");
      }
    }
  };

  const deleteAllData = async () => {
    let confirmDelete = prompt("Are you sure? Type 'yes' or 'Yes' for delete.");
    if (confirmDelete === "yes" || confirmDelete === "Yes") {
      datas.map((data, i) => {
        deleteBookedDetails(data._id, false);
      });
      toast.success("Data is deleting");
    }
  };

  useEffect(() => {
    fetchBookingData();
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    }
  }, [token]);

  // for pagination : Get Current datas
  const indexOfLastData = currentPage * postsPerPage;
  const indexOfFirstData = indexOfLastData - postsPerPage;
  const currentData = datas.slice(indexOfFirstData, indexOfLastData);
  console.log(currentData)

  const indexOfLastRegData = currentRegPage * postsPerPage;
  const indexOfFirstRegData = indexOfLastRegData - postsPerPage;
  const currentRegData = registeredUser.slice(
    indexOfFirstRegData,
    indexOfLastRegData
  );

  const indexOfLastGuestData = currentGuestPage * postsPerPage;
  const indexOfFirstGuestData = indexOfLastGuestData - postsPerPage;
  const currentGuestData = guestUser.slice(
    indexOfFirstGuestData,
    indexOfLastGuestData
  );

  // for pagination : Change page
  const paginate = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === ">") {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const paginateReg = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentRegPage(currentRegPage - 1);
    } else if (pageNumber === ">") {
      setCurrentRegPage(currentRegPage + 1);
    } else {
      setCurrentRegPage(pageNumber);
    }
  };

  const paginateGuest = (pageNumber) => {
    if (pageNumber === "<") {
      setCurrentGuestPage(currentGuestPage - 1);
    } else if (pageNumber === ">") {
      setCurrentGuestPage(currentGuestPage + 1);
    } else {
      setCurrentGuestPage(pageNumber);
    }
  };

  return (
    <>
      <div className={style.BookingTableWrapper}>
        {loading ? (
          <div className={style.loadingTextWrapper}>
            <h1 className={style.loadingText}>... Loading ...</h1>
          </div>
        ) : (
          <div className={style.BookingTable}>
            <div className={style.FormHeading}>
              <h1>All Bookings</h1>
            </div>

            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="all"
              >
                <FormControlLabel
                  value="all"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("all");
                      }}
                    />
                  }
                  label="All"
                />
                <FormControlLabel
                  value="RegisteredUser"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("RegisteredUser");
                      }}
                    />
                  }
                  label="Registered User"
                />
                <FormControlLabel
                  value="GuestUser"
                  sx={{
                    color: amber[600],
                    "&.Mui-focused": {
                      color: amber[400],
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: amber[600],
                        "&.Mui-checked": {
                          color: amber[400],
                        },
                      }}
                      onClick={() => {
                        setFilter("GuestUser");
                      }}
                    />
                  }
                  label="Guest User"
                />
              </RadioGroup>
            </FormControl>

            {filter === "all" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={datas.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}

            {filter === "RegisteredUser" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={registeredUser.length}
                paginate={paginateReg}
                currentPage={currentRegPage}
              />
            )}

            {filter === "GuestUser" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={guestUser.length}
                paginate={paginateGuest}
                currentPage={currentGuestPage}
              />
            )}

            {userData.role === "superAdmin" && (
              <div
                className={style.deleteAllBooking}
                onClick={() => {
                  deleteAllData();
                }}
              >
                Delete All Bookings
              </div>
            )}

            <table>
              <tbody>
                <tr>
                  <th style={{ width: "3%" }}>SN</th>
                  <th style={{ width: "13%" }}>Customer</th>
                  <th style={{ width: "13%" }}>Car Name</th>
                  <th style={{ width: "5%" }}>Total Cost</th>
                  <th style={{ width: "13%" }}>Date</th>
                  <th style={{ width: "12%" }}>Time</th>
                  <th style={{ width: "18%" }}>Location</th>
                  <th style={{ width: "5%" }}>Service Type</th>
                  <th style={{ width: "5%" }}>Payment</th>
                  <th style={{ width: "13%" }}>Status</th>
                </tr>
                {filter === "all" && (
                  <>
                    {Array.isArray(currentData) &&
                      currentData.map((data, i) => {
                        return (
                          <>
                            <AdminBookingList
                              key={i}
                              data={data}
                              i={indexOfFirstData + i}
                              role={userData.role}
                              fetchDataFun={fetchBookingData}
                            />
                          </>
                        );
                      })}
                  </>
                )}

                {filter === "RegisteredUser" && (
                  <>
                    {Array.isArray(currentRegData) &&
                      currentRegData.map((data, i) => {
                        return (
                          <>
                            <AdminBookingList
                              key={i}
                              data={data}
                              i={indexOfFirstRegData + i}
                              role={userData.role}
                              fetchDataFun={fetchBookingData}
                            />
                          </>
                        );
                      })}
                  </>
                )}

                {filter === "GuestUser" && (
                  <>
                    {Array.isArray(currentGuestData) &&
                      currentGuestData.map((data, i) => {
                        return (
                          <>
                            <AdminBookingList
                              key={i}
                              data={data}
                              i={indexOfFirstGuestData + i}
                              role={userData.role}
                              fetchDataFun={fetchBookingData}
                            />
                          </>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>

            {filter === "all" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={datas.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}

            {filter === "RegisteredUser" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={registeredUser.length}
                paginate={paginateReg}
                currentPage={currentRegPage}
              />
            )}

            {filter === "GuestUser" && (
              <PaginationComp
                postsPerPage={postsPerPage}
                totalPosts={guestUser.length}
                paginate={paginateGuest}
                currentPage={currentGuestPage}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ViewBookings;
