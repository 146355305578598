import React, { useEffect, useRef, useState } from "react";
import style from "./AdminLogin.module.css";
import axios from "axios";
import { Navigate } from "react-router-dom";

// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const usernameRef = useRef();
  const errorRef = useRef();
  // const navigate = useNavigate();
  // const location = useLocation();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [auth, setAuth] = useState(null);


  const from = "/admin/dashboard";

  // useEffect(() => {
  // 		//------------------------------ redirect to dashboard if auth.role is found stored in  local storage. -----------------------------------------------------------------
  // 		if (auth.Role) {
  // 			Navigate("/admin/dashboard");
  // 		}
  // 		// eslint-disable-next-line react-hooks/exhaustive-deps
  // 	}, []);

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      email: email,
      password: password,
    };
    // todo: need to be fixed
    setErrMsg("Not connected to backend yet");

    try {
    	const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, postData);
    	const accessToken = response?.data?.token;
    	const role = response?.data?.user.role;
    	let localData = { LocalToken: accessToken, LocalRole: role };
      let d=new Date()
    	let lt = { StoreLoginTime: d.getTime() };
    	localStorage.setItem("User Info", JSON.stringify(localData));
    	localStorage.setItem("User time", JSON.stringify(lt));
    	setAuth({ AuthRole: role, AuthAccessToken: accessToken });
    	if (response.data) {
    		setemail("");
    		setPassword("");
    	}
    	window.location.href = `${from}`;
    } catch (err) {
    	if (!err?.response) {
    		setErrMsg("No Server Response");
    	} else if (err.response?.status === 400) {
    		setErrMsg("Missing Username or Password");
    	} else if (err.response?.status === 401) {
    		setErrMsg("Unauthorized");
    	} else {
    		setErrMsg("Login Failed");
    	}
    }
  };

  return (
    <div>
      <div className={style.AdminLoginWrapper}>
        <div className={style.AdminLogin}>
          <div className={style.Heading}>
            <h1>
              Admin <span className={style.HeadingHighlight}>Login</span>
            </h1>
            <h2>Enter your credentials to sign in to your account </h2>
          </div>
          <p ref={errorRef} className={errMsg ? "errmeg" : "offscreen"}>
            {errMsg}
          </p>

          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={style.FormWrappper}
          >
            <label htmlFor="username">
              <h1>Email</h1>
            </label>
            <input
              type="text"
              id="username"
              ref={usernameRef}
              onChange={(e) => setemail(e.target.value)}
              value={email}
              required
            />
            <label htmlFor="password">
              <h1>Password</h1>
            </label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <button>Sign in</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
