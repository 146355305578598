import React from "react";
import style from "./Comming.module.css";

const Comming = () => {
	return (
		<div className={style.CommingMessage}>
			<h1>
				Oops, you caught us in the middle of a makeover. This page is undergoing some major renovations and will be ready soon. Thank you for your
				patience!
			</h1>
		</div>
	);
};

export default Comming;
