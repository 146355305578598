import React from "react";
import style from "./UpdateAdmin.module.css";
import { useParams } from "react-router";

const UpdateAdmin = () => {
	const { adminID } = useParams();
	return (
		<>
			<div className={style.AddAdminWrapper}>
				<div className={style.AddAdmin}>
					<div className={style.FormHeading}>
						<h1>Update Admin {adminID}</h1>
					</div>
					<form autoComplete="off" className={style.AdminForm}>
						<h1>Admin Name</h1>
						<input name="VehicleName" type="text" required aria-label="Vehicle Name"></input>
						<h1>Access type</h1>
						<select name="AccessType">
							<option value="null">Select category</option>
							<option value="SuperAdmin">Super Admin</option>
							<option value="Admin">Admin</option>
						</select>

						<button>Submit</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default UpdateAdmin;
