import React from "react";
import style from "./UnavailaleTime.module.css";
import DateFormatter from "../DateFormatter/DateFormatter";
import uuid from "react-uuid";

export default function UnavailableTime(props) {
	const { content, dates, selDate } = props;
	return (
		<>
			<div className={style.unavailableTimeWrapper}>
				<div className={style.unavailableTimeDetails}>
					<h3>
						<DateFormatter selDate={selDate} />
					</h3>
					<h3>Unavailable Time</h3>
					<div className={style.uTimeTable}>
						<ul>
							{dates &&
								content === "yes" &&
								dates.map((date) => (
									<li key={uuid()}>
										{date.startTime} - {date.endTime}
									</li>
								))}

							{content === "no" && <h3>No time slot are booked for the day</h3>}

							{content === "loading" && <h3>Please wait while we check for unavailable times</h3>}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
