import React, { useEffect, useState } from "react";
import style from "./Register.module.css";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import { storeData } from "../../constants/apiService";

const Register = () => {
  const emailRef = useRef();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isAlradyLoggedIn = localStorage.getItem("access_token_cb");

  // const google = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}auth/google`, "_self");
  // };

  // const facebook = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}auth/facebook`, "_self");
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const url = "/user/register";

    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const registerData = {
      fullName: enterdData.FullName,
      email: enterdData.Email,
    };

    const response = await storeData(url, registerData);

    if (response.data) {
      setLoading(false);
      toast.success("Check your email for the password.");

      navigate("/login");
      // setLoadings(true)
    } else {
      setLoading(false);
      toast.error(response.response.data.error);
      // setLoadings(false)
    }
  };
  useEffect(() => {
    if (isAlradyLoggedIn) {
      navigate("/");
    }

    // emailRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.LoginWrapper}>
          <div className={style.WaitingMessage}>
            <h1>... LOADING ...</h1>
          </div>
        </div>
      ) : (
        <div className={style.LoginWrapper}>
          <h1>Register</h1>
          <form
            onSubmit={handleSubmit}
            className={style.FormWrapper}
            autoComplete="off"
          >
            <h2>Full Name</h2>
            <input type="text" ref={emailRef} required name="FullName"></input>
            <h2>Email</h2>
            <input type="email" required name="Email"></input>
            <button>Register</button>

            <hr style={{ margin: "10px 0px" }} />
            {/* <SocialButton
              logo={<FcGoogle />}
              title="Sign up with Google"
              linkTo={google}
            />
            <SocialButton
              logo={<MdFacebook />}
              title="Sign up with Facebook"
              linkTo={facebook}
            /> */}
            <Link to={"/login"}>
              <h3>Already have account ?</h3>
            </Link>
          </form>
        </div>
      )}
    </>
  );
};

export default Register;
