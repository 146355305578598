import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import style from "./AdminLayout.module.css";
import AdminSidebar from "../AdminSideBar/AdminSideBar";
import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const AdminLayout = () => {
  let token = localStorage.getItem("access_token_cb");
  const navigate = useNavigate();
  const verifyTokenAndTimeOut = async () => {
    try {
      const url = "/user/verify";
      const tokenData = { token: token };
      const result = await storeData(url, tokenData);
      if (result.response.status === 401) {
        localStorage.removeItem("access_token_cb");
        toast.error("Please Relogin Again");
        navigate("/");
      }
    } catch (err) {
      console.log("verification token");
    }
  };

  useEffect(() => {
    verifyTokenAndTimeOut();
  }, []);

  return (
    <div className={style.AdminLayout}>
      <div className={style.stickeySide}>
        <AdminSidebar />
      </div>
      <Outlet />
    </div>
  );
};

export default AdminLayout;
