import React, { useEffect, useState } from "react";
import style from "./IndividualCategory.module.css";
import { useParams } from "react-router-dom";
import { getFormDataById } from "../../constants/apiService";
import Disclaimer from "../../Components/Disclaimer/Disclaimer";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import ReactHTMLParser from "html-react-parser";

const IndividualCategory = () => {
  const dummyArr = [0, 1, 2, 3, 4, 5];

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const { category } = useParams();

  const fetchByCategory = async () => {
    const url = `cars/getcarbycategory/${category}`;

    const result = await getFormDataById(url);

    if (result.status === 200) {
      setLoading(false);
      let cars = result.data;
      setData(cars);
    } else if (result.status === 404) {
      toast.error(result.statusText);
    } else if (result.status === 400) {
      toast.error(result.data.errors[0].message);
    } else {
      toast.error("error fetching data");
    }
  };

  useEffect(() => {
    fetchByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);
  return (
    <>
      {!loading && !data && (
        <div className={style.CategoryWrapper}>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                  <div className={style.H1Div}></div>
                  <div className={style.H2Div}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {data && (
        <div className={style.CategoryWrapper}>
          <div className={style.Heading}>
            <h1>{category}</h1>
          </div>
          <div className={style.CategoryList}>
            {data?.map((Car) => (
              <div className={style.IndividualCar} key={Car._id}>
                <img
                  src={`${Car.imageUrl}`}
                  alt="Car"
                />

                <div className={style.CarInfo}>
                  <h1>{Car.name}</h1>
                  <div className={style.descriptionP}>
                    {ReactHTMLParser(Car.description)}
                  </div>
                  <Link to={`/booking/${Car._id}`}>
                    <button>Book Now</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Disclaimer />
    </>
  );
};

export default IndividualCategory;
