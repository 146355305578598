import React, { useContext, useEffect } from "react";
import "./DashTheme2.css";
import PieChartContainer from "../PieChartContainer/PieChartContainer";
import BarChartContainer from "../BarChartContainer/BarChartContainer";
import CardComponent from "../../../Components/Cards/CardComponent";
import { Button } from "@mui/material";
import NotificationItem from "../../../Components/NotificationItem/NotificationItem";
import CategoryContext from "../../../Context/Category/CategoryContext";
import BookingContext from "../../../Context/Booking/BookingContext";
import CarReportContext from "../../../Context/CarReport/CarReportContext";
import NotificationContext from "../../../Context/Notification/NotificationContext";

export default function DashTheme2() {
  const { fetchAllCategory, totalCategory } = useContext(CategoryContext);

  const {
    fetchBookingData,
    totalEarning,
    bookingData,
    totalCompletedService,
    totalRemainingService,
  } = useContext(BookingContext);

  const { fetchCarReportData, crloading, carReportData, createAllCarReport } =
    useContext(CarReportContext);

  const { fetchNotificationData, notificationData } =
    useContext(NotificationContext);

  useEffect(() => {
    fetchNotificationData();
    fetchBookingData();
    fetchAllCategory();
    fetchCarReportData();
  }, []);

  return (
    <>
      <div className="DashBoardCardGrid">
        <CardComponent
          title={"Total Earning"}
          value={"$ " + totalEarning.toFixed(2)}
          customClass={"success"}
        />
        <CardComponent
          title={"Total Cars"}
          value={carReportData ? carReportData.length : "0"}
        />
        <CardComponent title={"Total Category"} value={totalCategory} />
        <CardComponent
          title={"Completed Service"}
          value={totalCompletedService}
        />
        <CardComponent title={"Booking Order"} value={totalRemainingService} />
      </div>

      <div className="DashBoardChartViewFlex">
        <div className="ChartViewFlexLeft">
          <Button className="cusmuiBTN muiBTNactive">Notification</Button>
          <div className="notificationContainer">
            {notificationData &&
              notificationData.slice(0, 10).map((notiData, i) => {
                return (
                  <NotificationItem
                    title={notiData.userName}
                    content={notiData.message}
                    action={notiData.action}
                    actionType={notiData.actionType}
                    date={notiData.createdAt}
                    key={i}
                  />
                );
              })}
          </div>
        </div>
        <div className="ChartViewFlexRight">
          <BarChartContainer />
          <br />
          <PieChartContainer />
          <br />
        </div>
      </div>
    </>
  );
}
