import React from "react";
import moment from "moment";

export default function DateFormatter(props) {
  const { selDate } = props;
  const year = moment(selDate).format("YYYY");
  let month = moment(selDate).format("MM");
  const selmon = month.slice(0, 1);
  if (selmon === "0") {
    month = month.slice(1, 2);
  }
  const day = moment(selDate).format("DD");
  let pmonth = "";

  switch (Number(month)) {
    case 1:
      pmonth = "January";
      break;
    case 2:
      pmonth = "Feburary";
      break;
    case 3:
      pmonth = "March";
      break;
    case 4:
      pmonth = "April";
      break;
    case 5:
      pmonth = "May";
      break;
    case 6:
      pmonth = "June";
      break;
    case 7:
      pmonth = "July";
      break;
    case 8:
      pmonth = "August";
      break;
    case 9:
      pmonth = "September";
      break;
    case 10:
      pmonth = "October";
      break;
    case 11:
      pmonth = "November";
      break;
    case 12:
      pmonth = "December";
      break;
    default:
      pmonth = month;
      break;
  }
  //   const year = moment(selDate).format("MM/DD/YYYY");

  return day + " " + pmonth + ", " + year;
}
