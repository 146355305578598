import React, { useEffect, useState } from "react";
import style from "./AdminSideBar.module.css";
import "./AdminSideBarExtra.css";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/actual_logo.jpg";
import jwt_decode from "jwt-decode";
import { MdManageAccounts } from "react-icons/md";

import { AdminNavLinks } from "../../Data/AdminNavLinks";
import { SuperAdminNavLinks } from "../../Data/SuperAdminNavLinks";

const AdminSideBar = () => {
  let token = localStorage.getItem("access_token_cb");
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken);
    }
    // eslint-disable-next-line
  }, []);

  const ShowSecondarySideBar = () => {
    const secSideBarId = document.getElementById("SecondarySideBarWrapper");
    if (secSideBarId) {
      secSideBarId.classList.remove("hideSideBar");
    }
  };

  return (
    <div className={style.AdminSideBarWrapper}>
      <div className={style.AdminSideBar}>
        <div className={style.Logo}>
          <Link to={"/"} aria-label="Visit to admin dashboard">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        <div className={style.DashboardLinks + " DashboardLinksz"}>
          {AdminNavLinks.map((link, key) => {
            return (
              <NavLink to={link.Linkto} key={key}>
                <h1 key={key}>
                  {link.Logo} <span> {link.Title}</span>
                </h1>
              </NavLink>
            );
          })}

          {userData.role === "superAdmin" &&
            SuperAdminNavLinks.map((link, key) => {
              return (
                <NavLink
                  to={link.Linkto}
                  key={key}
                  onClick={link.SideBar ? () => ShowSecondarySideBar() : ""}
                >
                  <h1 key={key}>
                    {link.Logo} <span>{link.Title}</span>
                  </h1>
                </NavLink>
              );
            })}
        </div>

        <div className={style.AdminSideBarFooter}>
          <div className={style.AdminSBFooter_left}>
            <MdManageAccounts />
          </div>
          <div className={style.AdminSBFooter_right}>
            {/* <h3>{userData?.fullName}</h3> */}
            <ul>
              <li>{userData?.email}</li>
              <li>{userData?.role}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
