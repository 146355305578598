import React, { useContext, useEffect, useState } from "react";
import "../ChartContainer.css";
import BarChartComponent from "../../../Components/Charts/BarChartComponent";
import LineChartComponent from "../../../Components/Charts/LineChartComponent";
import BookingContext from "../../../Context/Booking/BookingContext";

export default function BarChartContainer() {
  const { bookingData } = useContext(BookingContext);
  const [displayOption, setDisplayOption] = useState(7);
  let curr = new Date();

  // const dateC = new Date().toJSON().slice(0, 10);
  // console.log("date current : ", dateC);

  let years = [];
  let cashFlow = [];
  let dataSet = [];

  const runGraphProcessing = (x) => {
    for (let i = x; i >= 0; i--) {
      curr = new Date();
      let lastday1 = new Date(curr.setDate(curr.getDate() - i));
      years.push(lastday1);
    }

    years.map((year, i) => {
      let graphDate = year.toJSON().slice(0, 10);
      let barLabel;
      if (x === "7") {
        barLabel = year.toString().slice(0, 3);
      } else if (x === "30") {
        barLabel = year.toString().slice(8, 10);
      } else if (x === "365") {
        barLabel = year.toString().slice(4, 8);
      }
      let cash = 0;
      if (bookingData) {
        bookingData.map((data, j) => {
          // console.log("Date : ", data.createdAt.slice(0, 10), data.cost);
          let bookingDate = data.createdAt.slice(0, 10);
          if (graphDate === bookingDate) {
            cash = cash + data.cost;
          }
        });
      }
      cashFlow.push(cash);
      dataSet.push({ month: barLabel, Sales: cash });
    });
  };

  runGraphProcessing(displayOption);

  useEffect(() => {
    years = [];
    cashFlow = [];
    dataSet = [];
    runGraphProcessing(displayOption);
  }, [displayOption]);

  return (
    <div className="DashBoardChart">
      <div className="DashChart_TitleSelector">
        <div className="DashChart_Title">
          <h4 onClick={() => setDisplayOption(60)}>Sales Report</h4>
        </div>
        <div className="DashChart_Selector">
          <select
            className="form-select"
            aria-label="Default select example"
            value={displayOption}
            onChange={(e) => {
              setDisplayOption(e.target.value);
            }}
          >
            <option value="7">This Week</option>
            <option value="30">This Month</option>
            <option value="365">This Year</option>
          </select>
        </div>
      </div>
      {/* <BarChartComponent dataset={dataSet} /> */}
      <LineChartComponent years={years} cashFlow={cashFlow} />
    </div>
  );
}
