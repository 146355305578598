import React, { useEffect, useState } from "react";
import style from "./HeroEditable.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllData, updateData } from "../../constants/apiService";
import HeroImageFormTag from "./HeroImageFormTag";
import HeroVideoFormTag from "./HeroVideoFormTag";

export default function HeroEditable() {
  const [loading, setLoading] = useState(false);
  const [coverImageData, setCoverImageData] = useState();
  const [updatingData, setUpdatingData] = useState([]);
  const [video,setVideo]=useState([])
  const fetchCoverImageData = async () => {
    try {
      const url = "/image/getallimages/";
      const result = await getAllData(url);
      if (result.status === 200) {
        setCoverImageData(result.data.data);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };
  const fetchVideo = async () => {
    try {
      const url = "/image/getVideo";
      const result = await getAllData(url);
      if (result.status === 200) {
        setVideo(result.data.data);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };
  const updateSoloCoverImageData = async (coverImg_id, newData) => {
    setLoading(true);
    try {
      const url = "/image/editimage/" + coverImg_id;
      const result = await updateData(url, newData);
      if (result.status === 200 || result.status === 201) {
        toast.success("Link is updated");
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoverImageData();
    fetchVideo();
  }, []);

  const onDataChange = (e) => {
    console.log(updatingData)
    setUpdatingData((prevUpdatingData) => [
      ...prevUpdatingData,
      { id: e.target.id, imageUrl: e.target.files[0] },
    ]);
  };

  const onSubmitBTN = () => {
    // eslint-disable-next-line
    console.log(updatingData)
    updatingData.map((data, i) => {
      let menuItemData = new FormData();
      menuItemData.append("imageUrl", data.imageUrl);
      updateSoloCoverImageData(data.id, menuItemData);
    });
  };

  return (
    <>
      <div className={style.DesktopStyle}>
        <div className={style.HeroWrarpper}>
          <form>
            {coverImageData ? (
              <div className={style.GridContainer}>
                <div className={style.Left}>
                  <HeroImageFormTag
                    coverImageData={coverImageData}
                    keyId={"0"}
                    onDataChange={onDataChange}
                  />

                  <HeroImageFormTag
                    coverImageData={coverImageData}
                    keyId={"1"}
                    onDataChange={onDataChange}
                  />
                </div>
                <div className={style.Mid}>
                  <HeroVideoFormTag
                    coverVideoData={video}
                    keyId={"0"}
                  />
                  <Link onClick={onSubmitBTN}>
                    <button className={style.hero_btn}>
                      {loading ? "... LOADING ..." : "Update Cover Image"}
                    </button>
                  </Link>
                </div>
                <div className={style.Right}>
                  <HeroImageFormTag
                    coverImageData={coverImageData}
                    keyId={"2"}
                    onDataChange={onDataChange}
                  />
                  <HeroImageFormTag
                    coverImageData={coverImageData}
                    keyId={"3"}
                    onDataChange={onDataChange}
                  />
                </div>
              </div>
            ) : (
              "... LOADING..."
            )}
          </form>
        </div>
      </div>
    </>
  );
}
