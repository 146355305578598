import React, { useEffect, useState } from "react";
import style from "./StripeContainer.module.css";
import { getAllData, storeData } from "../../../../constants/apiService";
import { toast } from "react-toastify";
import { Switch, Tooltip } from "@mui/material";
import { TbBulb} from "react-icons/tb";

export default function StripeContainer() {
    const [loading,setLoading]=useState(true)
    const [visible,setVisible] = useState(false)
    const [name,setName]=useState("")
    const [apiKey,setApiKey]=useState("")
    const fetchStripeSetting=async ()=>{
        try{
            const url="/stripe/APIKEY"
            const response =await getAllData(url)
            if(response.status===200){
                setLoading(false)
                setName(response.data.data[0].ownerName)
                setApiKey(response.data.data[0].apiKey)
            }
            else {
                // Handle error scenarios here (e.g., show error message)
                console.error("Failed to fetch data:", response);
               setName("Owner Name Here")
               setApiKey("Stripe's Private API key here")
            }
        }
        catch(error){
            toast.error("Error fetching API KEY")
        }
        
    }
    const saveStripeSetting=async (e)=>{
        e.preventDefault()
        try{
            const url="/stripe/APIKEY"
            const response =await storeData(url,{ownerName:name,apiKey})
            if(response.status===201){
               toast.success("Stripe Account Saved.")
            }
            else {
                console.error("Failed to save data")
            }
        }
        catch(error){
            toast.error("Error saving data")
        }
        
    }

    useEffect(()=>{
        fetchStripeSetting()
    },[])
if(loading)
    return <h1>Loading</h1>

  return (
    <div className={style.wrapper}>
      <div className={style.stripe_container}>
        <h1>STRIPE SETUP</h1>

        <div className={style.stripe_stripy}>
         <form className={style.FORM}>
         Hide<Switch color="warning" checked={visible} onChange={()=>setVisible(prev=>!prev)} />Show
            <br/>
            <label
            className={style.Label}
            >Owner Name:-</label>
            <input
            type={visible ? 'text' : 'password'}
            value={name}
            onChange={(e)=>setName(e.target.value)}
            className={style.inputField} />
            <br></br>
            <label
            className={style.Label}
            >Stripe's Secret Key:-</label>
            <input
            type={visible ? 'text' : 'password'}
            value={apiKey}
            onChange={(e)=>setApiKey(e.target.value)}
            className={style.inputField} />
            <Tooltip
            title={
                <span style={{fontSize:"2em",color:"var(--hover-color)"}}>
                  -First Signin to stripe.<br />
                  -Complete your stripe account's profile.<br />
                  -Go to Developers option.<br />
                  -Reveal the Secret Key.<br />
                  -That is what you need to paste here.<br />
                  -This will enable you to accept payments form this site to your account.
                </span>
              }
              arrow
              placement="right"
              >
            <span><TbBulb style={{ width: "2em",height: "2em" }} /></span>
            </Tooltip>
            <br></br>
            <button
            className={style.saveBtn}
            onClick={(e)=>saveStripeSetting(e)}>Save</button>
         </form>
        </div>
      </div>
    </div>
  );
}
