import React, { useEffect, useState } from "react";
import style from "./PrivacyContainer.module.css";
import { getAllData, storeData } from "../../../../constants/apiService";
import { toast } from "react-toastify";
import {  Switch, Tooltip } from "@mui/material";
import { TbBulb} from "react-icons/tb";

export default function PrivacyContainer() {
    const [loading,setLoading]=useState(true)
    const [data,setData]=useState({})
    const fetchPrivacyPolicy=async ()=>{
        try{
            const url="/policy/getallpolicy"
            const response =await getAllData(url)
            if(response.status===200){
                setLoading(false)
                setData({...response.data.data[0]})
            }
            else {
                // Handle error scenarios here (e.g., show error message)
                console.error("Failed to fetch data:", response);
               setData({title:"Privacy Policy","description":"Your company's privacy policy...."})
            }
        }
        catch(error){
            toast.error("Error fetching API KEY")
        }
        
    }
    const savePrivacyPolicy=async (e)=>{
        e.preventDefault()
        try{
            const url="/policy/addpolicy"
            const response =await storeData(url,data)
            if(response.status===201){
               toast.success("Privay Policy Saved.")
            }
            else {
                console.error("Failed to save data")
            }
        }
        catch(error){
            toast.error("Error saving data")
        }
        
    }

    useEffect(()=>{
        fetchPrivacyPolicy()
    },[])
if(loading)
    return <h1>Loading</h1>

  return (
    <div className={style.wrapper}>
      <div className={style.stripe_container}>
        <h1>Privacy Policy Controller</h1>

        <div className={style.stripe_stripy}>
         <form className={style.FORM}>
            <label
            className={style.Label}
            >Heading:-</label>
            <input
            type="text"
            value={data.title}
            onChange={e=>setData((prev)=>
                {
                    return {...prev,title:e.target.value}
                }
            )}
            className={style.inputField} />
            <br></br>
            <label
            className={style.Label}
            >Description:-</label>
            <textarea
            rows={12}
            type="text"
            value={data.description}
            onChange={e=>setData((prev)=>({...prev,description:e.target.value}))}
            className={style.inputField} />
            <Tooltip
            title={
                <span style={{fontSize:"2em",color:"var(--hover-color)"}}>
                  Your company's privacy Policy.<br />
                  This field can handle long text.
                </span>
              }
              arrow
              placement="right"
              >
            <span><TbBulb style={{ width: "2em",height: "2em" }} /></span>
            </Tooltip>
            <br></br>
            <button
            className={style.saveBtn}
            onClick={(e)=>savePrivacyPolicy(e)}>Save</button>
         </form>
        </div>
      </div>
    </div>
  );
}
