import React from "react";
import style from "./StepAndDash.module.css";

export default function StepAndDash(props) {
  const { width, step } = props;
  return (
    <div className={style.stepToFollow} style={{ width: width }}>
      <div className={style.stf_left}>
        <h3>STEP {step}</h3>
      </div>
      <div className={style.stf_right}>
        <hr />
      </div>
    </div>
  );
}
