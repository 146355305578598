import React, { useEffect, useState } from "react";
import style from "./AboutUsPage.module.css";
import { getAllData } from "../../constants/apiService";
import HTMLReactParser from "html-react-parser";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import MediaIcon from "../../Components/MediaIcon/MediaIcon";
import uuid from "react-uuid";

export default function AboutUsPage() {
  const [loading, setLoading] = useState(false);
  const [aboutData, setAboutData] = useState("... LOADING ...");
  const [socialMediaData, setSocialMediaData] = useState();
  const [contactData, setContactData] = useState({
    name: "-",
    contact_number: "-",
    address: "-",
    email: "-",
  });

  const dummyArr = [0, 1];

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const url = "/contactinfo/getallcontactinfo/";
      const result = await getAllData(url);
      if (result.status === 200) {
        const collectedData = result.data.data[0];
        setContactData({
          name: collectedData.name,
          contact_number: collectedData.phone_number,
          address: collectedData.address,
          email: collectedData.email,
        });
        setLoading(false);
      } else {
        toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      toast.error("Some error occurred");
      setLoading(false);
    }
  };

  const fetchSocialMediaData = async () => {
    setLoading(true);
    try {
      const url = "/social/getallsocial/";
      const result = await getAllData(url);
      if (result.status === 200) {
        setSocialMediaData(result.data.data);
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  const fetchAbout = async () => {
    setLoading(true);
    try {
      const url = "/about/getallabout";
      const result = await getAllData(url);
      if (result.status === 200) {
        const resData = result.data.data[0].description;
        setAboutData(resData);
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAbout();
    fetchContactData();
    fetchSocialMediaData();
  }, []);

  return (
    <>
      {loading && (
        <div className={style.AboutUsWrapper}>
          <div className={style.Heading}>THE START OF NTX LIMO</div>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.H2Div}></div>
                  {/* <div className={style.H1Div}></div> */}
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {contactData && socialMediaData && !loading && (
        <div className={style.AboutUsWrapper}>
          <div className={style.Heading}>THE START OF NTX LIMO</div>
          <div className={style.AboutUsContainer}>
            <div className={style.AboutUsDiv1}>
              <div className={style.AboutUsUs}>
                <p>{HTMLReactParser(aboutData)}</p>
              </div>
            </div>

            <div className={style.AboutUsDiv2}>
              <div className={style.AboutUsInfo}>
                <h2>{contactData.name}</h2>
                <br />
                <h3>{contactData.address}</h3>
                <h3>Office : {contactData.contact_number}</h3>
                <h3>Email : {contactData.email}</h3>
                <h3>Reach Us : 24 Hours</h3>

                <div className={style.Social}>
                  <h1>Socials</h1>
                  <hr className={style.AboutUsHR} />
                  <div className={style.SocialIcon}>
                    {Array.isArray(socialMediaData) &&
                      socialMediaData.map((smData, i) => {
                        return (
                          <Link to={smData.link} target="_blank">
                            <MediaIcon iconName={smData.platform} />
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
