import React, { useEffect, useState } from "react";
import style from "./ViewCar.module.css";
import { MdDeleteSweep, MdOutlineModeEditOutline } from "react-icons/md";

import { Link } from "react-router-dom";
import { deleteById, getAllFormData } from "../../../constants/apiService";
import { Button, Space, Tooltip, Popconfirm } from "antd";

// import toast from "react-hot-toast";
import { toast } from "react-toastify";

export default function ViewCar() {
  const [datas, setDatas] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const url = "/cars/getallcars";

      const result = await getAllFormData(url);

      if (result.status === 200) {
        let cars = result.data;
        console.log("cars all : ", cars);
        setDatas(cars);
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const handleDelete = async (id) => {
    if (id) {
      const url = `/cars/deletecar/${id}`;
      const result = await deleteById(url);
      if (result.status === 200) {
        toast.success("Car Deleted Sucessfully");
        fetchAll();
      } else if (result.response.status === 404) {
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        toast.error(result.response.data.errors[0].message);
      } else {
        toast.error(result.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      {loading ? (
        <div className={style.loadingTextDiv}>
          <h1 className={style.loadingText}>... Loading ...</h1>
        </div>
      ) : (
        <div className={style.ViewCarTableWrapper}>
          <div className={style.ViewCarTable}>
            <div className={style.FormHeading}>
              <div className={style.FH_left}>
                <Link to="/admin/car/addCar" className={style.baseBTN}>
                  Add Cars
                </Link>
              </div>
              <div className={style.FH_right}>
                <h1>Car's List</h1>
              </div>
            </div>
            <table className={style.VCTable}>
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>SN</th>
                  <th style={{ width: "10%" }}>Image</th>
                  <th style={{ width: "15%" }}>Category</th>
                  <th style={{ width: "15%" }}>Car Name</th>
                  <th style={{ width: "15%" }}>Rate Per Hour ($)</th>
                  <th style={{ width: "10%" }}>Passenger Capacity</th>
                  <th style={{ width: "10%" }}>Luggage Capacity</th>
                  <th style={{ width: "20%" }}>Actions</th>
                </tr>
                {Array.isArray(datas) &&
                  datas.map((data, i) => {
                    return (
                      <tr key={data._id}>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            className=""
                            src={
                              data.imageUrl
                            }
                            alt="Id proof"
                            width="60px"
                          />
                        </td>
                        <td>{data.category}</td>
                        <td>{data.name}</td>
                        <td>{data.perHourRent}</td>
                        <td>{data.capacity}</td>
                        <td>
                          {data.luggageCapacity ? data.luggageCapacity : "0"}
                        </td>
                        <td>
                          <Space size="middle">
                            <Link
                              className="mr-1"
                              size="small"
                              style={{ fontSize: "22px", padding: "0 10px" }}
                              to={`/admin/car/` + data._id}
                            >
                              <MdOutlineModeEditOutline
                                style={{ color: "green" }}
                              />
                            </Link>

                            <Tooltip placement="bottomRight" title="Delete">
                              <Popconfirm
                                title="Delete record?"
                                onConfirm={() => handleDelete(data._id)}
                              >
                                <Button
                                  className="mr-1"
                                  style={{
                                    fontSize: "22px",
                                    padding: "0 10px",
                                  }}
                                  size="small"
                                >
                                  <MdDeleteSweep style={{ color: "red" }} />
                                </Button>
                              </Popconfirm>
                            </Tooltip>
                          </Space>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
