import React, { useEffect, useState } from "react";
import style from "./VehicleList.module.css";
import { getAllFormData } from "../../constants/apiService";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const VehicleList = ({ filter, categoryTitleID }) => {
  //states
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const dummyArr = [0, 1, 2, 3, 4, 5];

  const fetchAll = async () => {
    setLoading(true);
    const url = "cars/getallcars";

    const result = await getAllFormData(url);

    if (result.status === 200) {
      setLoading(false);
      let cars = "";
      if (filter === "homepage") {
        cars = result.data.slice(0, 6);
      } else if (filter === "all") {
        cars = result.data;
      } else {
        cars = result.data.filter((item) => item.category === filter);
      }
      setData(cars);

      // to hide the category title if the data is null
      if (categoryTitleID && cars.length === 0) {
        const selectTitleDiv = document.getElementById(categoryTitleID);
        if (selectTitleDiv) {
          selectTitleDiv.style.display = "none";
        }
      }
    } else if (result.status === 404) {
      // setLoading(false);
      toast.error(result.statusText);
    } else if (result.status === 400) {
      // setLoading(false);
      toast.error(result.data.errors[0].message);
    } else {
      // setLoading(false);
      // toast.error("error fetching data");
    }
  };

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && (
        <div className={style.SkelWrapper}>
          {dummyArr.map(() => (
            <div key={uuid()} className={style.Training}>
              <div className={style.Skel}>
                <div className={style.imgDiv}></div>
                <div className={style.H1Div}></div>
                <div className={style.H2Div}></div>
              </div>
            </div>
          ))}
        </div>
      )}
      {data && !loading && (
        <div className={style.VehicleList}>
          {data?.map((Vehicle) => (
            <div className={style.VehicleCard} key={Vehicle._id}>
              <img
                src={`${Vehicle.imageUrl}`}
                alt="Car"
              />
              <div className={style.MoreInfo} key={Vehicle.Key}>
                <h2>{Vehicle.name ? Vehicle.name : "Vehicle Name"}</h2>
                <hr />
                <ul>
                  <li>Rent: ${Vehicle?.perHourRent} /hr</li>
                  <li>Passenger Capacity: {Vehicle?.capacity}</li>
                  <li>Fuel Type: {Vehicle?.fuelType}</li>
                </ul>
              </div>
              <Link to={`/booking/${Vehicle._id}`}>
                <button> Book Now</button>
              </Link>
              <div className={style.RateContainer}>
                <h1> $ {Vehicle.perHourRent}/ Per Hour</h1>
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && filter === "homepage" && (
        <Link to={"/reservation"} className={style.wideBTNdiv}>
          <div className={style.wideBTN}>View All</div>
        </Link>
      )}
    </>
  );
};

export default VehicleList;
