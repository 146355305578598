import React, { useEffect, useState } from 'react'
import style from "./serviceContainer.module.css";
import { deleteById, getAllData, storeData } from '../../../../constants/apiService';
import { toast } from 'react-toastify';

const ServiceContainer = () => {
  const [services,setServices]=useState([])
  const [inputValue, setInputValue] = useState('');

  const fetchServices=async ()=>{
    try{
        const url="/service/getallservice"
        const response =await getAllData(url)
        if(response.status===200){
          setServices(response?.data?.data)
        }
        else {
            // Handle error scenarios here (e.g., show error message)
            console.error("Failed to fetch data:", response);
        }
    }
    catch(error){
        toast.error("Error fetching services list")
    }
    
}
useEffect(()=>{
  fetchServices()
},[])
   // Function to handle the addition of a new service
   const handleAddService = async (e) => {
    e.preventDefault();
    if(!inputValue.trim()){
      toast.error("Service Name cannot be empty")
    }
    try{
      const data={"serviceName":inputValue.trim()}
      const url="/service/addservice"
      const response =await storeData(url,data)
      if(response.status===201){
         toast.success("Service Added.")
         setInputValue("")
          fetchServices()
      }
      else {
          console.error("Failed to save data")
      }
  }
  catch(error){
      toast.error("Error saving data")
  }
  };

  // Function to handle the deletion of a service
  const handleDeleteService = async (serviceId) => {
    try{
      const url=`/service/deleteservice/${serviceId}`
      console.log(url)
      const response =await deleteById(url)
      console.log(response)
      if(response.status===200){
         toast.success("Service Deleted.")
         setServices(services.filter(service => service._id !== serviceId));
      }
      else {
          console.error("Failed to delete data")
      }
  }
  catch(error){
      toast.error("Error saving data")
  }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.stripe_container}>
        <h1>Booking Services Controller</h1>

        <div className={style.stripe_stripy}>
         <form className={style.FORM}>
            <label
            className={style.Label}
            >Service Name:-</label>
            <input
            type="text"
            className={style.inputField} value={inputValue} onChange={(e)=>{
              setInputValue(e.target.value)
            }}/>
            <button
            className={style.saveBtn} onClick={(e)=>handleAddService(e)}>Add</button>
         </form>

         <ul className={style.serviceList}>
            {services.map((service, index) => (
              <li key={index} className={style.serviceItem}>
                {service.serviceName}
                <button
                  className={style.deleteBtn}
                  onClick={() => handleDeleteService(service._id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>

        </div>
      </div>
    </div>
  )
}

export default ServiceContainer
