import React, { useState } from "react";
import style from "./Login.module.css";
import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const Login = () => {
  const emailRef = useRef();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const prevLocation = useLocation();

  // const isAlradyLoggedIn = localStorage.getItem("access_token_cb");

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);

    const data = new FormData(e.target);
    let enterdData = Object.fromEntries(data.entries());

    const url = "/user/login";

    const loginData = {
      email: enterdData.Email,
      password: enterdData.Password,
    };
    const response = await storeData(url, loginData);

    if (response.data) {
      const { token } = response.data;
      localStorage.setItem("access_token_cb", token); //setting access token in localStorage
      setLoading(false);
      toast.success("User Logged In Sucessfully");
      navigate(prevLocation);
    } else {
      setLoading(false);
      toast.error("Invalid Credintals");
    }
  };

  // useEffect(() => {
  //   if (isAlradyLoggedIn) {
  //     navigate("/");
  //   }
  //   // emailRef.current.focus();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {loading ? (
        <div className={style.LoginWrapper}>
          <h1>... Authenticating ... </h1>
        </div>
      ) : (
        <div className={style.LoginWrapper}>
          <h1>Login</h1>
          <form
            onSubmit={handleSubmit}
            className={style.FormWrapper}
            autoComplete="off"
          >
            <h2>Email</h2>
            <input type="email" ref={emailRef} required name="Email"></input>
            <h2>Password</h2>
            <input type="password" required name="Password"></input>
            <button>Submit</button>
            <Link to={"/register"}>
              <h3>Create Account</h3>
            </Link>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
