import React from "react";
import addImage from "../../assets/staticImage/add icon.jpg";

export default function HeroImageFormTag(props) {
  const { coverImageData, keyId, onDataChange } = props;

  const previewSelectedImage = (imgId, inputId) => {
    const imageInput = document.getElementById(inputId);
    const previewImage = document.getElementById(imgId);
    const file = imageInput.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        previewImage.src = e.target.result;
      };
    }
  };
  return (
    <label
      for={coverImageData[keyId] ? coverImageData[keyId]._id : "coverinput0"}
    >
      <img
        src={
          coverImageData[keyId]
            ? 
              coverImageData[keyId].imageUrl
            : addImage
        }
        alt={"Cover Image 00" + keyId}
        id={"coverimg" + keyId}
      ></img>
      <input
        type="file"
        id={coverImageData[keyId] ? coverImageData[keyId]._id : "coverinput0"}
        onChange={(e) => {
          previewSelectedImage(
            "coverimg" + keyId,
            coverImageData[keyId] ? coverImageData[keyId]._id : "coverinput0"
          );
          onDataChange(e);
        }}
      />
    </label>
  );
}
