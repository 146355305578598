import React, { useEffect } from "react";
import style from "./PaymentFailed.module.css";
import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { deleteById } from "../../constants/apiService";

export default function PaymentFailed() {
  let { bookedID } = useParams();

  const deleteBookedDetails = async () => {
    const url = `/bookings/removebooking/${bookedID}`;
    const result = await deleteById(url);
    if (result.status === 200) {
      console.log("deleted Details");
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    deleteBookedDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className={style.PaymentWrapper}>
        <div className={style.Heading}>Payment Failed</div>
        <div className={style.PaymentContainer}>
          <div className={style.PayDetailContainer}>
            <h2>Your Booking was canceled</h2>
            <div className={style.failedIcon}>
              <MdCancel />
            </div>
            <div className={style.failedDetailsContainer}>
              <Link to={"/reservation"}>
                <div className={style.redBTN}>RETRY BOOKING</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
