import React, { useEffect, useState } from "react";
import VehicleList from "../../Components/VehicleList/VehicleList";
import style from "./ReservationPage.module.css";
import { getAllFormData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import uuid from "react-uuid";

export default function ReservationPage() {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState("");

  const dummyArr = [0, 1];

  const fetchAllCategory = async () => {
    setLoading(true);
    try {
      const url = "category/getallcategory";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let categories = await result.data;
        setCategories(categories);
        setLoading(false);
      } else if (result.status === 404) {
        // toast.error("Some error occurred");
        setLoading(false);
      } else if (result.status === 400) {
        // toast.error("Some error occurred");
        setLoading(false);
      } else {
        // toast.error("Some error occurred");
        setLoading(false);
      }
    } catch (err) {
      // toast.error("Some error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  return (
    <>
      {loading && (
        <div className={style.ReservationWrapper}>
          <div className={style.Heading}>RESERVATION</div>
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.H2Div}></div>
                  {/* <div className={style.H1Div}></div> */}
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {categories && !loading && (
        <div className={style.ReservationWrapper}>
          <div className={style.Heading}>RESERVATION</div>
          <div className={style.ReservationContainer}>
            {Array.isArray(categories) &&
              categories.map((category, i) => (
                <section key={i}>
                  <div
                    className={style.ReserveContainer_title}
                    id={"category" + i}
                  >
                    <h1>{category.title}</h1>
                    <hr />
                  </div>
                  <VehicleList
                    filter={category.title}
                    categoryTitleID={"category" + i}
                  />
                </section>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
