import React from "react";
import "./SettingLayout.css";
import SecondarySideBar from "../SecondarySideBar/SecondarySideBar";
import { Outlet } from "react-router-dom";

export default function SettingLayout() {
  return (
    <div className="SettingLayoutWrapper">
      <SecondarySideBar />
      <Outlet />
    </div>
  );
}
