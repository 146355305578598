import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { getAllData, storeData } from "../../constants/apiService";
// import { toast } from "react-hot-toast";
import { toast } from "react-toastify";

const ClientLayout = () => {
  let token = localStorage.getItem("access_token_cb");
  const navigate = useNavigate();

  const verifyTokenAndTimeOut = async () => {
    try {
      const url = "/user/verify";
      const tokenData = { token: token };
      const result = await storeData(url, tokenData);
      if (result.response.status === 401) {
        localStorage.removeItem("access_token_cb");
        // toast.error("Please Relogin Again");
        navigate("/");
      }
    } catch (err) {
      console.log("Failed verification token");
    }
  };

  //  ------------------------------------------------------------------ Common style for all client side pages.------------------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [cssData, setCssData] = useState();

  const fetchAllCSS = async () => {
    setLoading(true);
    const url = "css/getallcss";

    const result = await getAllData(url);

    if (result.status === 200) {
      setLoading(false);
      setCssData(result.data);
    } else if (result.status === 404) {
      setLoading(false);
      toast.error(result.statusText);
    } else if (result.status === 400) {
      setLoading(false);
      toast.error(result.data.errors[0].message);
    } else {
      setLoading(false);
      // toast.error("error fetching data");
    }
  };

  useEffect(() => {
    if (token) {
      verifyTokenAndTimeOut();
    }
    fetchAllCSS();
    // eslint-disable-next-line
  }, []);

  let selectedBG = "#252525";
  let selectedContentColor = "#f0d679";
  let selectedHoverColor = "#dcac00";
  let selectedSecondaryColor = "#E7C54D"; //In myBooking page

  if (Array.isArray(cssData)) {
    cssData.map((data, i) => {
      if (data.property === "--background-color") {
        selectedBG = data.value;
      } else if (data.property === "--text-color") {
        selectedContentColor = data.value;
      } else if (data.property === "--darker-color") {
        selectedSecondaryColor = data.value;
      } else if (data.property === "--hover-color") {
        selectedHoverColor = data.value;
      }
    });
  }

  const changeStyleDiv = () => {
    document.documentElement.style.setProperty(
      "--background-color",
      selectedBG
    );
    document.documentElement.style.setProperty(
      "--text-color",
      selectedContentColor
    );
    document.documentElement.style.setProperty(
      "--darker-color",
      selectedSecondaryColor
    );
    document.documentElement.style.setProperty(
      "--hover-color",
      selectedHoverColor
    );
  };

  changeStyleDiv();

  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default ClientLayout;
