import React, { useEffect, useState } from "react";
import "./ThemeContainer.css";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import { getAllData, updatePutData } from "../../../../constants/apiService";

export default function ThemeContainer() {
  const [loading, setLoading] = useState(false);
  const [cssData, setCssData] = useState();
  // trying to change root color

  const fetchAllCSS = async () => {
    setLoading(true);
    const url = "css/getallcss";

    const result = await getAllData(url);

    if (result.status === 200) {
      setLoading(false);
      setCssData(result.data);
    } else if (result.status === 404) {
      setLoading(false);
      toast.error(result.statusText);
    } else if (result.status === 400) {
      setLoading(false);
      toast.error(result.data.errors[0].message);
    } else {
      setLoading(false);
      toast.error("error fetching data");
    }
  };

  const onSubmitClick = async (
    colorInputId,
    colorDBProperty,
    defaultValue = false
  ) => {
    setLoading(true);

    let colorDBId;
    cssData.map((data, i) => {
      if (data.property === colorDBProperty) {
        colorDBId = data._id;
      }
    });

    const url = `/css/update/${colorDBId}`;
    let newColor;
    if (defaultValue) {
      newColor = colorInputId;
    } else {
      newColor = document.getElementById(colorInputId).value;
    }

    let updatedColorArray = {
      value: newColor,
    };

    try {
      const result = await updatePutData(url, updatedColorArray);
      if (result.status === 200) {
        setLoading(false);
        toast.success("Color Change Success");
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchAllCSS();
  }, []);
  return (
    <>
      {loading && (
        <div className="LoadingWrapper">
          <h1>... Loading ... </h1>
        </div>
      )}
      {!loading && (
        <div className="ThemeWrapper">
          <div className="ThemeContainer">
            <h1>Change Theme Color</h1>

            <div className="SystemColorChangerWrapper">
              <div className="SystemColorChangerContainer">
                <table>
                  <tr className="SystemColorSelecterDiv">
                    <td>
                      <span>Background Color : </span>
                    </td>
                    <td>
                      <input
                        type="color"
                        name="BackgroundColor"
                        id="Background_Color"
                      />
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick(
                            "Background_Color",
                            "--background-color"
                          )
                        }
                      >
                        Update Theme
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("#252525", "--background-color", true)
                        }
                      >
                        Change to Default
                      </button>
                    </td>
                  </tr>

                  <tr className="SystemColorSelecterDiv">
                    <td>
                      <span>Base Color : </span>
                    </td>
                    <td>
                      <input type="color" name="BaseColor" id="Base_Color" />
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("Base_Color", "--text-color")
                        }
                      >
                        Update Theme
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("#f0d679", "--text-color", true)
                        }
                      >
                        Change to Default
                      </button>
                    </td>
                  </tr>

                  <tr className="SystemColorSelecterDiv">
                    <td>
                      <span>Hover Color : </span>
                    </td>
                    <td>
                      <input type="color" name="HoverColor" id="Hover_Color" />
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("Hover_Color", "--hover-color")
                        }
                      >
                        Update Theme
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("#dcac00", "--hover-color", true)
                        }
                      >
                        Change to Default
                      </button>
                    </td>
                  </tr>

                  <tr className="SystemColorSelecterDiv">
                    <td>
                      <span>Secondary Color : </span>
                    </td>
                    <td>
                      <input
                        type="color"
                        name="SecondaryColor"
                        id="Secondary_Color"
                      />
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("Secondary_Color", "--darker-color")
                        }
                      >
                        Update Theme
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          onSubmitClick("#E7C54D", "--darker-color", true)
                        }
                      >
                        Change to Default
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
