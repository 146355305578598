import React, { useEffect, useState } from "react";
import style from "./ViewContacts.module.css";

// import toast from "react-hot-toast";
import { toast } from "react-toastify";

import { getAllFormData } from "../../../constants/apiService";

export default function ViewContacts() {
  const [datas, setDatas] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchContactsData = async () => {
    setLoading(true);
    try {
      const url = "/contacts/getallcontact";
      const result = await getAllFormData(url);
      if (result.status === 200) {
        let contact = result.data;
        setDatas(contact.reverse());
        setLoading(false);
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error(result.response.statusText);
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error(result.response.data.errors[0].message);
      } else {
        setLoading(false);
        toast.error(result.response.data.reason);
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  useEffect(() => {
    fetchContactsData();
  }, []);

  return (
    <>
      <div className={style.ContactTableWrapper}>
        {loading ? (
          <div className={style.loadingTextWrapper}>
            <h1 className={style.loadingText}>... Loading ...</h1>
          </div>
        ) : (
          <div className={style.ContactTable}>
            <div className={style.FormHeading}>
              <h1>Contact Messages</h1>
            </div>

            <table>
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>SN</th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th style={{ width: "20%" }}>Email</th>
                  <th style={{ width: "15%" }}>Mobile Number</th>
                  <th style={{ width: "40%" }}>Description</th>
                </tr>
                {Array.isArray(datas) &&
                  datas.map((data, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{data.name ? data.name : "null"}</td>
                        <td>{data.email ? data.email : "null"}</td>
                        <td>
                          {data.mobileNumber ? data.mobileNumber : "null"}
                        </td>
                        <td>{data.address ? data.address : "null"}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
