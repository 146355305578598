import React from "react";
import style from "./Disclaimer.module.css";
import dis1 from "../../assets/Disclaimer/blob v2.png";
import dis2 from "../../assets/Disclaimer/HourlyPay.png";
import dis3 from "../../assets/Disclaimer/Minimum_hour.png";

const Disclaimer = () => {
  return (
    <>
      <div className={style.MoreInfo}>
        <div className={style.Icons}>
          <div className={style.IconsContent}>
            <img src={dis1} alt="" />
            <div className={style.IC_details}>
              <span>One Way Trip ?</span>
              <span>Starting At $*</span>
            </div>
          </div>

          <div className={style.IconsContent}>
            <img src={dis2} alt="" />
            <div className={style.IC_details}>
              <span>Need Hourly Service?</span>
              <span>Starting At $*</span>
            </div>
          </div>

          <div className={style.IconsContent}>
            <img src={dis3} alt="" />
            <div className={style.IC_details}>
              <span>Minimum Hour</span>
              <span>1 Hour</span>
            </div>
          </div>

          {/* <img src={Logo} alt="" />
          <img src={Logo} alt="" /> */}
        </div>
        <div className={style.Terms}>
          <ul>
            <li>Prices include all fees (tax, tolls, fuel, and gratuity)</li>
            <li>Mileage restrictions may apply</li>
            <li>Rates may vary depending on the date and time of event</li>
            <li>Hourly minimums based on non-peak</li>
            <li>Terms of Conditions apply to every reservation</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
