import React, { useEffect, useRef, useState } from "react";
import style from "./PaymentSuccess.module.css";
import { MdCheckCircle } from "react-icons/md";
import Flex2item from "../../Components/Flex2item/Flex2item";
import Logo from "../../assets/Logo.jpg";

import { useParams } from "react-router-dom";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";
import {
  getDataById,
  storeData,
  // storeFormData,
  // updateData,
} from "../../constants/apiService";
import moment from "moment";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function PaymentSuccess() {
  let { bookedID } = useParams();
  const [bookedData, setBookedData] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);

  const pdfRef = useRef();

  const fetchBookedDetails = async () => {
    setLoading(true);
    try {
      const url = `/bookings/getbookingbyid/${bookedID}`;
      const result = await getDataById(url);

      if (result.status === 200) {
        setBookedData(result.data.data);
        setLoading(false);
        setDataFound(true);
        paidStatusChanger();
      } else if (result.response.status === 404) {
        setLoading(false);
        toast.error("Data Not Found");
      } else if (result.response.status === 400) {
        setLoading(false);
        toast.error("Data Not Found");
      } else {
        setLoading(false);
        toast.error("Data Not Found");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Data Not Found");
    }
  };

  const paidStatusChanger = async () => {
    setLoading(true);
    const url = `/payment/changepaymentstatus/${bookedID}`;
    const result = await storeData(url, { paymentStatus: true });
    if (result.status === 200) {
      setLoading(false);
    } else if (result.response.status === 404) {
      toast.error(result.response.statusText);
    } else if (result.response.status === 400) {
      toast.error(result.response.data.errors[0].message);
    } else {
      toast.error(result.response.data.reason);
    }
  };

  const dateGiver = (dateTime) => {
    const date = moment(dateTime).format("YYYY-MM-DD");
    const time = moment(dateTime).format("hh:mm:A");
    return date + " @ " + time;
  };

  const downloadPDF = async () => {
    setDownloadLoading(true);
    const input = pdfRef.current;
    // let vp = input.getAttribute("content");
    // console.log('vp : ', vp)
    const name = bookedData.user
      ? bookedData.user.fullName
      : bookedData.guestUser.fullName;

    html2canvas(input, {
      logging: false,
      useCORS: true,
      windowWidth: "1600px",
      imageTimeout: 10000,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Delay creation to ensure image is ready
      setTimeout(() => {
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0; //to change top and bottom margin
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save(`NTXLimo_invoice_${name}.pdf`);
        setDownloadLoading(false);
      }, 1000); // Delay of 1 second (adjust as needed)
    });

    // setDownloadLoading(false);
  };

  useEffect(() => {
    fetchBookedDetails();
  }, []);

  return (
    <>
      {loading && (
        <div className={style.PaymentWrapper}>
          <div className={style.WaitingMessage}>
            <h1>... Loading ...</h1>
          </div>
        </div>
      )}

      {!loading && !dataFound && (
        <div className={style.PaymentWrapper}>
          <div className={style.WaitingMessage}>
            <h1>Data Error</h1>
          </div>
        </div>
      )}

      {!loading && dataFound && bookedData && (
        <>
          <div className={style.PaymentWrapper}>
            {/* <div className={style.Heading}>Payment Success</div> */}
            <div className={style.DownloadPDFWrapper}>
              <div className={style.DownloadPDFContainer}>
                {downloadLoading ? (
                  <Flex2item
                    type="singlebutton"
                    rightText="Loading"
                    rightURL=""
                  />
                ) : (
                  <Flex2item
                    type="singlebutton"
                    rightText="Download PDF"
                    rightURL=""
                    onCLickRightBTN={downloadPDF}
                  />
                )}
              </div>
            </div>

            <div className={style.invoiceWrapper} ref={pdfRef}>
              <div className={style.LogoViewWrapper}>
                <div className={style.LogoViewContainer}>
                  <img src={Logo} alt="logo" />
                </div>
              </div>
              <div className={style.Heading}>Payment Success</div>
              <div className={style.PaymentContainer}>
                <div className={style.PayDetailContainer}>
                  <h2>Your Booking was successful</h2>
                  <div className={style.successIcon}>
                    <MdCheckCircle />
                  </div>
                  <br />
                  <div className={style.successDetailsContainer}>
                    <Flex2item
                      type="text"
                      leftText="Transaction Id"
                      rightText={bookedData._id}
                    />
                    <Flex2item
                      type="text"
                      leftText="Name"
                      rightText={
                        bookedData.user
                          ? bookedData.user.fullName
                          : bookedData.guestUser.fullName
                      }
                    />
                    <Flex2item
                      type="text"
                      leftText="Email"
                      rightText={
                        bookedData.user
                          ? bookedData.user.email
                          : bookedData.guestUser.email
                      }
                    />
                    <Flex2item
                      type="text"
                      leftText="Amount Paid"
                      rightText={"$ " + bookedData.cost.toFixed(2)}
                    />

                    <Flex2item
                      type="text"
                      leftText="Car Booked"
                      rightText={bookedData.car.name}
                    />

                    <Flex2item
                      type="text"
                      leftText="Pick Up Date"
                      rightText={dateGiver(bookedData.startTime)}
                    />

                    <Flex2item
                      type="text"
                      leftText="Drop Off Date"
                      rightText={dateGiver(bookedData.endTime)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={style.CarDetailContainer}>
              <Flex2item
                type="date"
                leftText={dateGiver(bookedData.startTime)}
                rightText={dateGiver(bookedData.endTime)}
              />

              <hr />
              <Flex2item
                type="image"
                leftURL={bookedData.car.imageUrl}
                rightText={bookedData.car.name}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
