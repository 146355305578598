import React, { useEffect, useState } from "react";
import style from "./MyBooking.module.css";

import jwt_decode from "jwt-decode";
import { getDataById } from "../../constants/apiService";
// import { getDataById, getDataByIdInBody } from "../../constants/apiService";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import uuid from "react-uuid";

import { storeData } from "../../constants/apiService";
// import toast from "react-hot-toast";
import { toast } from "react-toastify";

const MyBookings = () => {
  let token = localStorage.getItem("access_token_cb");
  let authUser = JSON.parse(localStorage.getItem("authKey"));
  const [userData, setUserData] = useState("");
  const [myBookings, setMyBookings] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);

  const dummyArr = [0, 1, 2];

  //fetching car details
  const fetchBookings = async () => {
    try {
      if (userData) {
        const url = `/bookings/myBookings/${userData}`;
        const result = await getDataById(url);

        if (result.status === 200) {
          setLoading(false);
          setMyBookings(result.data.data.reverse());
        } else if (result.response.status === 404) {
          setLoading(false);
          // toast.error(result.response.statusText);
        } else if (result.response.status === 400) {
          setLoading(false);
          // toast.error(result.response.data.errors[0].message);
        } else {
          setLoading(false);
          // toast.error(result.response.data.reason);
        }
      }
    } catch (e) {
      toast.error("Server Error");
    }
  };

  const handleStatusChange = async (value, recordItem) => {
    setStatusLoading(true);
    let statusData = {
      status: value,
    };
    const url = `/bookings/${recordItem}`;
    const result = await storeData(url, statusData);
    if (result.status === 200 && value === "Pending") {
      toast.success(`Rerservation Cancellation is requested.`);
      setStatusLoading(false);
    } else {
      toast.success(`Rerservation requested again.`);
      setStatusLoading(false);
    }
    fetchBookings();
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setUserData(decodedToken.id);
    }
    if (authUser) {
      setUserData(authUser.id);
    }
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <div className={style.MyBookingsWrapper}>
        <div className={style.Heading}>
          <h1>My Bookings</h1>
        </div>
        {loading ? (
          <div className={style.SkelWrapper}>
            {dummyArr.map(() => (
              <div key={uuid()} className={style.Training}>
                <div className={style.Skel}>
                  <div className={style.imgDiv}></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={style.BookingLists}>
            {Array.isArray(myBookings) && myBookings.length > 0 ? (
              myBookings.map((result, i) => (
                <div className={style.IndividualBookingDetail} key={i}>
                  <div className={style.InfoArea}>
                    <img
                      src={
                        result.car
                          ? `${result.car.imageUrl}`
                          : "https://img.freepik.com/premium-vector/car-cartoon-vehicle-transportation-isolated_138676-2473.jpg?w=2000"
                      }
                      alt="car"
                    ></img>
                    <div className={style.BookingDetailTexts}>
                      <h1>
                        {result.car ? result.car.name : "Unknown Car Name"}
                      </h1>

                      <div className={style.DataTable}>
                        <div className={style.TableHeading}>
                          <h2>Starting Date</h2>
                          <h2>Starting Time</h2>
                          <h2>PickUp Location</h2>
                          <h2>Ending Date</h2>
                          <h2>Ending Time</h2>
                          <h2>Drop Location</h2>
                          <h2>Cost</h2>
                        </div>
                        <div className={style.TableData}>
                          <h2>
                            {moment(result?.startTime).format("YYYY-MM-DD")}
                          </h2>
                          <h2>{moment(result?.startTime).format("hh:mm:A")}</h2>
                          <h2>{result?.from}</h2>
                          <h2>
                            {moment(result?.endTime).format("YYYY-MM-DD")}
                          </h2>
                          <h2>{moment(result?.endTime).format("hh:mm:A")}</h2>
                          <h2>{result?.to}</h2>
                          <h2>{result?.cost.toFixed(2)}</h2>
                        </div>
                      </div>

                      <div className={style.MoreInfo}>
                        <h3 className={style.mi_one}>
                          Passenger :{" "}
                          {result.passengers ? result.passengers : "0"}
                        </h3>
                        <h3 className={style.mi_two}>
                          Luggage :{" "}
                          {result.luggageCount ? result.luggageCount : "0"}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className={style.ActionButtons}>
                    {statusLoading ? (
                      <h2 className={style.statusLoadingH}>... Loading ...</h2>
                    ) : (
                      <>
                        <div className={style.ActionBTN_left}>
                          <button value={result?.cancelStatus}>
                            Cancel Status : {result?.cancelStatus}
                          </button>
                        </div>
                        <div className={style.ActionBTN_right}>
                          {result.cancelStatus === "Pending" && (
                            <button
                              className={style.aBTNrevert}
                              value={"order" + i}
                              onClick={(e) => {
                                e.preventDefault();
                                handleStatusChange("null", result._id);
                              }}
                            >
                              Revert Decision
                            </button>
                          )}

                          {result.cancelStatus === "null" && (
                            <div
                              className={style.statusActionBTN}
                              style={{ display: "flex", float: "right" }}
                            >
                              <Link to={`/invoice/${result._id}`}>
                                <button
                                  className={style.printBTN}
                                  value={result._id}
                                >
                                  Print
                                </button>
                              </Link>
                              <button
                                className={style.cancelBTN}
                                value={"order" + i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleStatusChange("Pending", result._id);
                                }}
                              >
                                Cancel Reservation
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={style.NoBookingContainer}>
                <h1 className="mt-4">!! You have not BOOKED anything !!</h1>
                <Link to={"/reservation"} className={style.NoBookBTN}>
                  BOOK NOW
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MyBookings;
