import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const Experiments = () => {
	const autoplayOptions = {
		delay: 5000,
	};

	const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay(autoplayOptions)]);

	return (
		<div className="embla" ref={emblaRef}>
			<div className="    embla__container">
				<div className="embla__slide">
					<img
						src="https://images.unsplash.com/photo-1683580607825-9d270ddbb3c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
						alt=""
					/>
				</div>
				<div className="embla__slide">
					<img
						src="https://images.unsplash.com/photo-1684034419034-579813d37561?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
						alt=""
					/>
				</div>
				<div className="embla__slide">
					<img
						src="https://images.unsplash.com/photo-1513407030348-c983a97b98d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default Experiments;
