import {
  MdDashboard,
  MdDirectionsCar,
  MdShoppingCart,
  MdCategory,
  MdMarkEmailUnread,
} from "react-icons/md";

export const AdminNavLinks = [
  {
    Key: 1,
    Title: "Dashboard",
    Linkto: "/admin/dash",
    Logo: <MdDashboard />,
  },
  {
    Key: 2,
    Title: "Bookings",
    Linkto: "/admin/ViewBookings",
    Logo: <MdShoppingCart />,
  },
  {
    Key: 3,
    Title: "Car Details",
    Linkto: "/admin/car",
    Logo: <MdDirectionsCar />,
  },
  {
    Key: 4,
    Title: "Category",
    Linkto: "/admin/category",
    Logo: <MdCategory />,
  },
  {
    Key: 5,
    Title: "Contacts",
    Linkto: "/admin/contact",
    Logo: <MdMarkEmailUnread />,
  },
];
