import React, { useEffect, useState } from "react";
import style from "./Hero.module.css";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/staticImage/Loading.gif";
import { getAllData } from "../../constants/apiService";
import { toast } from "react-toastify";

const Hero = () => {
  const autoplayOptions = {
    delay: 5000,
  };

  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay(autoplayOptions),
  ]);
  //   const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()])
  const [coverImageData, setCoverImageData] = useState([]);
  const [video, setVideo] = useState([]);


  const fetchCoverImageData = async () => {
    try {
      const url = "/image/getallimages/";
      const result = await getAllData(url);
      if (result.status === 200) {
        setCoverImageData(result.data.data);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };
  const fetchVideo = async () => {
    try {
      const url = "/image/getVideo";
      const result = await getAllData(url);
      if (result.status === 200) {
        setVideo(result.data.data);
      } else {
        // toast.error("Some error occurred");
      }
    } catch (err) {
      // toast.error("Some error occurred");
    }
  };
  useEffect(() => {
    fetchCoverImageData();
    fetchVideo();
  }, []);

  return (
    <>
      <div className={style.DesktopStyle}>
        <div className={style.HeroWrarpper}>
          <div className={style.GridContainer}>
            <div className={style.Left}>
              <img
                src={
                  coverImageData[0]
                    ? 
                      coverImageData[0].imageUrl
                    : defaultImg
                }
                alt="Car"
              ></img>
              <img
                src={
                  coverImageData[1]
                    ?
                      coverImageData[1].imageUrl
                    : defaultImg
                }
                alt="Car"
              ></img>
            </div>
            <div className={style.Mid}>
              <video
              autoPlay
              muted
              loop
                src={
                      video[0]?.videoUrl
                }
                alt="Car"
              ></video>
              <Link to="/reservation">
                <button className={style.hero_btn}>Book Now</button>
              </Link>
            </div>
            <div className={style.Right}>
              <img
                src={
                  coverImageData[2]
                    ?  
                      coverImageData[2].imageUrl
                    : defaultImg
                }
                alt="Car"
              ></img>
              <img
                src={
                  coverImageData[3]
                    ? 
                      coverImageData[3].imageUrl
                    : defaultImg
                }
                alt="Car"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className={style.MobileStyle}>
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {coverImageData.map((Image, id) => (
              <div className="embla__slide" key={id}>
                <img
                  src={
                    Image
                      ?  Image.imageUrl
                      : defaultImg
                  }
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <Link to="/reservation"> Book Now </Link>
      </div>
    </>
  );
};

export default Hero;
